import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Card,
  FormLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Box,
} from '@material-ui/core';
import {
  ListAlt,
  Check,
  Work,
  Telegram,
  AccountCircle
} from '@material-ui/icons';
import Page from 'src/components/Page';
import AuthContext from 'src/contexts/AuthContext';
import { useSnackbar } from 'notistack';
import showSnack from 'src/utils/snacks';
import api from '../../services/api';

import ImgImersao from '../../assets/images/Imersao_ambiente_profissional_aiamis.png';
import moment from 'moment';
import { isAfter } from 'date-fns';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    background: '#eee',
    padding: 10,
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '-1px -9px 40px #888',
    marginBottom: 20
  },
  gridCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px dashed #bbb'
  },
  btnNavigation: {
    backgroundColor: '#b00f14',
    padding: 10,
    borderRadius: 8,
    textAlign: 'center',
    margin: 15,
    cursor: 'pointer',
    height: '100%',
    '&:hover': {
      background: '#820d10',
      color: '#fff'
    }
  },
  btnNavigationDisabled: {
    backgroundColor: '#756a6a',
    padding: 10,
    borderRadius: 8,
    textAlign: 'center',
    margin: 15,
    cursor: 'pointer',
    height: '100%',
    '&:hover': {
      background: '#544d4d',
      color: '#fff'
    },
    display: 'none'
  },
  btnFinished: {
    backgroundColor: '#0cb96e',
    padding: 10,
    borderRadius: 8,
    textAlign: 'center',
    margin: 15,
    cursor: 'pointer',
    height: '100%',
    '&:hover': {
      background: '#0f9058',
      color: '#fff'
    }
  },
  imgImersaoProfi: {
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      height: 230,
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      height: 400,
      width: '100%'
    }
  },
  imgDetalhesCurso: {
    borderRadius: 8,
    [theme.breakpoints.down('xs')]: {
      height: '8rem'
    },
    [theme.breakpoints.up('sm')]: {
      height: '24rem'
    },
    [theme.breakpoints.up('md')]: {
      height: 390
    },
    [theme.breakpoints.up('lg')]: {
      height: 440
    },
    [theme.breakpoints.up('xl')]: {
      height: 400
    }
  }
}));

const Dashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { ra } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [liberarBotaoEnvioAcoes, setLiberarBotaoEnvioAcoes] = useState(true);
  const [liberarBotaoPortfolio, setLiberarBotaoPortfolio] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [cursos, setCursos] = useState([]);
  const [cursoSelecionado, setCursoSelecionado] = useState(1);

  const [preencheu, setPreencheu] = useState(false);

  const nave = dir => {
    navigate(dir);
  };

  useEffect(() => {
    api
      .get('answer/1', {
        params: {
          ra
        }
      })
      .then(({ data }) => {
        if (data.question7) {
          setPreencheu(true);
        }
      })
      .catch(e => console.log(e));
  }, []);

  useEffect(() => {
    if (moment(new Date()).isAfter('2021-05-19 23:59:59')) {
      setLiberarBotaoEnvioAcoes(true);
    }

    if (moment(new Date()).isAfter('2021-05-23 23:59:59')) {
      setLiberarBotaoPortfolio(true);
    }
  }, []);

  useEffect(() => {
    const sessionCursos = sessionStorage.getItem('@c@couss@eea@alu');
    const sessionCursoSelecionado = sessionStorage.getItem('@c@cous@eea@');

    if (ra) {
      if (sessionCursos) {
        const results = JSON.parse(sessionCursos);
        setCursos(results);
        setCursoSelecionado(sessionCursoSelecionado);
        setLoadingPage(false);
      } else {
        api.post('aluno/data-pdf', {
          username: ra
        }).then(({data}) => {
          const results = data.aluno.cursos;
          sessionStorage.setItem('@c@cous@eea@', JSON.stringify(results));
          setCursos(results);
          setCursoSelecionado(sessionCursoSelecionado ?? results[0]?.nome_curso);
          setLoadingPage(false);
        }).catch((e) => {
          console.log(e);
        })
      }
    }
  }, [ra]);

  if (loadingPage) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gridGap={8}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: '0.8rem',
            color: '#444'
          }}
        >Por favor, aguarde...</Typography>
        <CircularProgress/>
      </Box>
    )
  }

  return (
    <Page className={classes.root} title="Início">
      <Container>
        <Grid container style={{ marginBottom: 20 }}>
          <Grid
            item
            xs={12}
            style={{
              backgroundImage: `url(${ImgImersao})`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center'
            }}
            className={classes.imgDetalhesCurso}
          />
          {/* <img
              alt="Logo AIAMIS"
              className={classes.imgImersaoProfi}
              src={ImgImersao}
            />
          </Grid> */}
        </Grid>

        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.gridCenter}>
            <Typography
              style={{
                color: '#444',
                fontSize: '2.5rem',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              Imersão em ambiente profissional
            </Typography>
          </Grid>
          
          {
            cursos.length > 1 ? (
              <Grid item xs={12} style={{ paddingTop: 8 }}>
                <FormControl variant="outlined">
                  <InputLabel>Selecione um curso:</InputLabel>
                  <Select
                    label="Selecione um curso:"
                    required
                    value={cursoSelecionado}
                    onChange={(e) => {
                      setCursoSelecionado(e.target.value);
                      sessionStorage.setItem('@c@cous@eea@', e.target.value);
                      showSnack(`O curso ${e.target.value} foi selecionado.`, enqueueSnackbar)
                    }}
                  >
                    {
                      cursos.map((curso) => (
                        <MenuItem key={curso.codigo_curso} value={curso.nome_curso}>{curso.nome_curso}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            ) : <></>
          }

          <Grid item xs={12} md={3} style={{ padding: 5 }}>
            <Card
              className={
                preencheu ? classes.btnFinished : classes.btnNavigation
              }
              onClick={() =>
                nave(preencheu ? '/app/form-finished' : '/app/form')
              }
            >
              <FormLabel
                style={{
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '25',
                  cursor: 'pointer'
                }}
              >
                {preencheu ? (
                  <Check style={{ fontSize: '5rem' }} />
                ) : (
                  <ListAlt style={{ fontSize: '5rem' }} />
                )}
                <br />
                <br />
                <br />
                Questionário Socioprofissional
              </FormLabel>
            </Card>
          </Grid>

          <Grid item xs={12} md={3} style={{ padding: 5 }}>
            {/* /app/detalhes-curso */}
            <Card
              className={classes.btnNavigation}
              onClick={() => nave('/app/detalhes-curso')}
            >
              <FormLabel
                style={{
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '25',
                  cursor: 'pointer'
                }}
              >
                <Work style={{ fontSize: '5rem' }} />
                <br />
                <br />
                <br />
                Conheça o campo de imersão profissional
              </FormLabel>
            </Card>
          </Grid>

          <Grid item xs={12} md={3} style={{ padding: 5 }}>
            {/* /app/enviar-acoes */}
            {liberarBotaoEnvioAcoes && (
              <Card
                className={classes.btnNavigation}
                onClick={() => nave('/app/enviar-acoes')}
              >
                <FormLabel
                  style={{
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: '25',
                    cursor: 'pointer'
                  }}
                >
                  <Telegram style={{ fontSize: '5rem' }} />
                  <br />
                  <br />
                  <br />
                  Envio das ações realizadas em ambiente profissional
                </FormLabel>
              </Card>
            )}
          </Grid>

          <Grid item xs={12} md={3} style={{ padding: 5 }}>
            {/* /app/list-portifolio */}
            {liberarBotaoPortfolio && (
              <Card
                className={classes.btnNavigation}
                onClick={() => nave('/app/list-portifolio')}
              >
                <FormLabel
                  style={{
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: '25',
                    cursor: 'pointer'
                  }}
                >
                  <AccountCircle style={{ fontSize: '5rem' }} />
                  <br />
                  <br />
                  <br />
                  Construção do portifolio
                </FormLabel>
              </Card>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              marginTop: 20,
              textAlign: 'center',
              borderTop: '1px dashed #bbb'
            }}
          >
            <Typography
              style={{ color: '#444', fontSize: '0.7rem', fontWeight: 'bold' }}
            >
              Copyright &copy; DIRIN - PROEAD - AIAMIS - 2020.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
