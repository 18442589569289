import axios from 'axios';

const URL = process.env.REACT_APP_HOST_API

const api = axios.create({
  baseURL: URL
});
export const apiPath = `${URL}/`;

export default api;
