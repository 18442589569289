import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Box,
  CircularProgress,
  IconButton,
  Grid,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import { Close, Description } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import showSnack from '../../../../utils/snacks';

import './styles.scss';

export default function SeletorArquivos({
  state,
  setState,
  termosCadastradas = []
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingComponentRegistro, setLoadingComponentRegistro] = useState(
    false
  );
  const [termosSelecteds, setTermosSelecteds] = useState();

  const onDrop = useCallback(acceptedFiles => {
    let arraySelected = [];

    if (acceptedFiles.length > 0) {
      if (state.length > 0) {
        acceptedFiles.map(data => {
          let pass = false;

          state.map(file => {
            if (file.name === data.name) {
              pass = true;
            }
          });

          if (!pass) {
            arraySelected.push(data);
          }
        });
      } else {
        arraySelected = acceptedFiles;
      }

      for (const iterator of arraySelected) {
        state.push(iterator);
      }
    }
  }, []);

  const onDropRejected = useCallback(e => {
    e[0].errors.map(error => {
      console.log(error);
      if (error.code === 'file-too-large') {
        alert('O arquivo enviado supera o limite de 45MB');
      }
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    maxFiles: 0,
    maxSize: 45 * 1024 * 1024,
    multiple: true,
    onDropRejected,
    accept: ['.pdf', '.png', '.jpeg', '.jpg']
  });

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    paddingTop: '30px',
    paddingBottom: '30px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#b0b0b0',
    borderStyle: 'dashed',
    backgroundColor: '#e0e0e0',
    color: '#000000aa',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const activeStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const getBase64 = async file => {
    const teste = new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function() {
        const base64data = reader.result;
        return resolve(base64data);
      };
    });

    return teste;
  };

  useEffect(() => {
    (async function() {
      return await Promise.all(
        state.map(async (dados, index) => {
          const response = await getBase64(dados);

          return (
            <Grid item xs={12} md={3} key={index} style={{ padding: 8 }}>
              <Card style={{ border: '1px solid #000' }}>
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                      }}
                    >
                      <IconButton
                        style={{
                          color: '#b00f14'
                        }}
                        onClick={() => {
                          setLoadingComponentRegistro(true);
                          state.splice(index, 1);

                          setState(state);
                          setTimeout(() => {
                            setLoadingComponentRegistro(false);
                          }, 10);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      style={{
                        background: '#91c3e2',
                        padding: 6,
                        borderRadius: 2,
                        wordWrap: 'break-word'
                      }}
                    >
                      {dados.name}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })
      );
    })().then(element => setTermosSelecteds(element));
  }, [state.length]);

  return (
    <div id="container-seletor-arquivo">
      <div
        {...getRootProps({
          style: {
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
          }
        })}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Solte o arquivo aqui.</p>
        ) : (
          <p id="mensagem-arquivo">
            Arraste e solte seus arquivos aqui
            <span>Ou</span>
            <Button variant="contained">
              Clique para selecionar os arquivos
            </Button>
            <span>Tamanho máximo: 5Mb</span>
          </p>
        )}

        <fieldset
          style={{
            width: '100%',
            border: '1px solid #888',
            marginBottom: 10,
            borderRadius: 4
          }}
        >
          <legend style={{ padding: 8 }}>Termos Selecionadas</legend>
          {loadingComponentRegistro ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Grid container>{termosSelecteds}</Grid>
          )}
        </fieldset>
      </div>
    </div>
  );
}
