import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider
} from '@material-ui/core';
import Page from 'src/components/Page';
import { Home, ArrowForward, AccessibilityNew } from '@material-ui/icons';
import AuthContext from 'src/contexts/AuthContext';

import api from '../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgLogin: {
    [theme.breakpoints.down('sm')]: {
      height: 100,
      width: 100
    },
    [theme.breakpoints.up('sm')]: {
      height: 150,
      width: 190
    }
  },
  container: {
    background: '#eee',
    padding: 10,
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '-1px -9px 40px #888',
    marginBottom: 20
  },
  gridCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px dashed #bbb'
  },
  btnSalvar: {
    margin: 5,
    backgroundColor: '#b00f14',
    color: '#fff',
    '&:hover': {
      background: '#820d10',
      color: '#fff',
      transition: 'background-color 0.5s'
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: 'Lato',
    color: '#555'
  },
  title: {
    color: '#b00f14',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: 'Lato'
  },
  subtitle: {
    color: '#444',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'Lato'
  },
  feedback: {
    padding: 20
  },
  list: {
    border: '1px solid #bbb',
    borderRadius: 8
  },
  avatar: {
    background: '#b00f14'
  },
  btnVoltar: {
    margin: 5,
    backgroundColor: '#e5b31a',
    color: '#444',
    '&:hover': {
      backgroundColor: '#c19716',
      transition: 'background-color 0.2s'
    }
  }
}));

const FormFinished = () => {
  const classes = useStyles();
  const { ra } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const [question7, setQuestion7] = useState('');
  const [question8, setQuestion8] = useState('');
  const [question9, setQuestion9] = useState('');
  const [question10, setQuestion10] = useState('');

  useEffect(() => {
    if(ra) {
      api
      .get('answer/1', {
        params: {ra}
      })
      .then(({ data }) => {
        if (data.error) {
          return (navigate('/app'));
        }
        setQuestion7(data.question7);

        let explode8 = data.question8.split('¢¬');

        const len8 = explode8.filter(qt8 => {
          return qt8 !== '';
        });

        let explode9 = data.question9.split('¢¬');

        const len9 = explode9.filter(qt9 => {
          return qt9 !== '';
        });

        let explode10 = data.question10.split('¢¬');

        const len10 = explode10.filter(qt10 => {
          return qt10 !== '';
        });

        setQuestion8(len8);
        setQuestion9(len9);
        setQuestion10(len10);
      })
      .catch(e => console.log(e));
    }
  }, [ra]);

  return (
    <Page className={classes.root} title="Parabéns e Obrigado!">
      <Container>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.gridCenter}>
            <img
              alt="Logo AIAMIS"
              className={classes.imgLogin}
              src="/favicon.png"
            />

            <Typography className={classes.title}>Parabéns!!!!</Typography>

            <Typography className={classes.subtitle}>
              Agradecemos por preencher o questionário socioprofissional
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.container}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid item xs={12} className={classes.feedback}>
            { 
              question7 === 'Não' || question8.length > 0 || question9.length > 0 || question10.length > 0 ? (
                <List className={classes.list}>
                {question7 === 'Não' && (
                  <>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                          <ArrowForward />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            <Typography className={classes.heading}>
                              Para lhe ajudar a organizar melhor seus horários de estudo que tal conhecer a ferramenta de <font style={{ color: '#b00f14', fontWeight: 'bold' }}>Gestão de Cronograma de Estudos  On-line</font>,
                              nela recomendamos 8 horas de estudo por semana que poderão ser distribuídas junto a outras atividades que você realiza cotidianamente.
                            </Typography>

                            <Button
                              href="http://prodipeuninta.online/cronograma/"
                              target="_blank"
                              className={classes.btnSalvar}
                            >
                              Acesse aqui
                            </Button>
                          </>
                        }
                      />
                    </ListItem>

                    <Divider variant="inset" component="li" />
                  </>
                )}

                {question8.length > 0 && (
                  <>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                          <ArrowForward />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            <Typography className={classes.heading}>
                              Aprender um pouco mais sobre uma disciplina que temos dificuldade é sempre importante, principalmente quando estamos
                              iniciando uma nova jornada que é um curso superior de graduação. Para reforçar os conteúdos das disciplinas de
                              {question8.map(qt8 => { return (<font style={{ color: '#b00f14'}}> {` ${qt8}, `} </font> ) })} conheça e participe dos <font style={{ color: '#b00f14'}}>cursos livres</font> disponíveis
                              no Ecossistema de Aprendizagem AIAMIS, são ofertados cursos on-line totalmente gratuitos com a possibilidade de 
                              emissão de certificado.
                            </Typography>

                            <Button
                              href="https://uninta.edu.br/site/cursos-gratuitos/"
                              target="_blank"
                              className={classes.btnSalvar}
                            >
                              Acesse aqui
                            </Button>
                          </>
                        }
                      />
                    </ListItem>

                    <Divider variant="inset" component="li" />
                  </>
                )}

                {question9.length > 0 && (
                  <>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                          <ArrowForward />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            <Typography className={classes.heading}>
                              O apoio ao estudante engloba preocupações institucionais como o atendimento aos acadêmicos em suas necessidades individuais e
                              coletivas, considerando por exemplo, as vertentes emocionais, cognitivas e sociais. Para lhe apoiar o UNINTA conta com uma equipe
                              de psicopedagogos disponíveis a lhe atender totalmente on-line. Conheça o Apoio Psicopedagógico e se desejar poderá agendar o seu
                              atendimento.
                            </Typography>

                            <Button
                              href="https://ecossistema.uninta.edu.br/index.php/welcome/card/22"
                              target="_blank"
                              className={classes.btnSalvar}
                            >
                              Acesse aqui
                            </Button>
                          </>
                        }
                      />
                    </ListItem>

                    <Divider variant="inset" component="li" />
                  </>
                )}

                {question10.length > 0 && (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <ArrowForward />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <Typography className={classes.heading}>
                            O Ecossistema de Aprendizagem AIAMIS conta com ferramentas de acessibilidade em todo o ecossistema e no material didático,
                            representado pelo ícone de acessibilidade <AccessibilityNew style={{ color: '#b00f14' }}/> e também com uma curadoria de recursos e ferramentas que
                            poderão ser instaladas em seu computador ou dispositivo para facilitar a sua  acessibilidade a esse novo mundo que é o curso
                            de nível superior.
                          </Typography>

                          <Button
                            href="https://ecossistema.uninta.edu.br/index.php/welcome/card/22"
                            target="_blank"
                            className={classes.btnSalvar}
                          >
                            Acesse aqui
                          </Button>
                        </>
                      }
                    />
                  </ListItem>
                )}
              </List>
              ) : null
            }

            <Typography
              style={{
                textAlign: 'center',
                margin: 5,
                color: '#b00f14',
                fontFamily: 'Lato',
                fontWeight: 'bold'
              }}
            >
              UNINTA, grande do tamanho dos seus sonhos!
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center', marginTop: 10 }}>
            <Button
              className={classes.btnVoltar}
              onClick={() => navigate('/app')}
            >
              <Home />
              &nbsp;&nbsp; Início
            </Button>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 20, textAlign: 'center' }}>
            <Typography
              style={{ color: '#444', fontSize: '0.7rem', fontWeight: 'bold' }}
            >
              Copyright &copy; DIRIN - PROEAD - AIAMIS - 2020.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default FormFinished;
