import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
    dialogTitle: {
        background: '#b00f14'
    },
    textTitle: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: 18,
        fontWeight: 'bold',
        color: '#b00f14'
    },
    txtAwait: {
        color: '#b00f14',
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 6,
        textAlign: 'center'
    },
    textAdd: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#2c2c2c',
        textAlign: 'center'
    }
}));


export default function DialogConfirmEnvio({
    open = false,
    loading = false,
    txtAdditional = '',
    setOpen = () => {},
    actionButtonConfirm = () => {}
}) {
    const classes = useStyles();

  return (
      <Dialog open={open} onClose={() => { !loading && setOpen(false) }}>
          <DialogTitle>
            <Typography className={classes.textTitle}>
                Confirmar Envio
            </Typography>
          </DialogTitle>

          <DialogContent dividers>
                {
                    loading ? (
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Typography className={classes.txtAwait}>O envio está sendo feito, por favor aguarde</Typography>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {txtAdditional && (
                                <Typography className={classes.textAdd}>{txtAdditional}</Typography>
                            )}

                            <Alert severity="warning">Ao confirmar o envio, você só poderá alterar os dados ao terminar todo o processo de envio de portifólio.</Alert>

                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={actionButtonConfirm}
                                    style={{ margin: 4 }}
                                >
                                    Confirmar e Enviar
                                </Button>

                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={() => setOpen(false)}
                                    style={{ margin: 4 }}
                                >
                                    Fechar
                                </Button>
                            </Box>
                        </>
                    )
                }
          </DialogContent>
      </Dialog>
  );
}