import React, { useContext } from 'react';

import { Outlet, Navigate } from 'react-router-dom';
import { CircularProgress, makeStyles } from '@material-ui/core';
import TopBar from './TopBar';
import AuthContext from 'src/contexts/AuthContext';
import Lottie from 'react-lottie';
import { Loading as lottieLoading } from '../../assets/lotties';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  imgLoading: {
    [theme.breakpoints.down('xs')]: {
      height: 310,
      width: 400
    },
    [theme.breakpoints.up('sm')]: {
      height: 390,
      width: 420
    },
    [theme.breakpoints.up('md')]: {
      height: 400,
      width: 440
    },
    [theme.breakpoints.up('lg')]: {
      height: 400,
      width: 450
    }
  }
}));

const MainLayout = () => {
  const classes = useStyles();
  const { isAuth, loading } = useContext(AuthContext);

  const Loading = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: lottieLoading,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          background: '#f0f0f0',
          gap: 10
        }}
      >
        {/* <Lottie options={defaultOptions} autoplay height={400} width={450} /> */}
        <img
          alt="Logo AIAMIS"
          src="/favicon.png"
          className={classes.imgLoading}
        />
        <CircularProgress />
      </div>
    );
  };

  if (loading) {
    return Loading();
  }

  if (!isAuth && !loading) {
    return <Navigate to="/" />;
  }

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
