import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import { Visibility, VisibilityOff, AccountCircle } from '@material-ui/icons';
import Page from 'src/components/Page';
import AuthContext from 'src/contexts/AuthContext';
import { useSnackbar } from 'notistack';
import showSnack from '../../utils/snacks';
import api from 'src/services/api';

import ImgImersao from '../../assets/images/Imersao_ambiente_profissional_aiamis.png';

import './styles.css';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ced4da',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgLogin: {
    [theme.breakpoints.down('sm')]: {
      height: 130,
      width: 150
    },
    [theme.breakpoints.up('sm')]: {
      height: 190,
      width: 225
    }
  },
  imgImersaoProfi: {
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      height: 230,
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      height: 320,
      width: '100%'
    }
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { setRa, isAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [raState, setRaState] = useState('');
  const [passwordState, setPassword] = useState('');
  const [showPasswordState, setShowPassword] = useState(false);

  const handleAuth = () => {
    if (raState && passwordState) {
      setLoading(true);
      api
        .post('authAluno', { password: passwordState, username: raState })
        .then(e => {
          if (e.data.isAuth) {
            api.post('aluno/data-pdf', {
              username: raState
            }).then(({data}) => {
              sessionStorage.setItem('@c@couss@eea@alu', JSON.stringify(data.aluno.cursos));
              setRa(raState);
              navigate('/app');
              showSnack('Logado com sucesso!', enqueueSnackbar);
              sessionStorage.setItem('@c@cous@eea@', e.data.course);
              setLoading(true);
            });
            // sessionStorage.setItem("@c@cous@eea@", 'LICENCIATURA EM HISTÓRIA');
          } else {
            showSnack('RA e/ou Senha incorretos!', enqueueSnackbar, 'error');
          }
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      showSnack('Preencha todos os campos!', enqueueSnackbar, 'warning');
    }
  };

  React.useEffect(() => {
    if (isAuth) {
      navigate('/app');
    }
  }, [isAuth]);

  return (
    <Page className={classes.root} title="Login - AIAMIS">
      <Container maxWidth="md" style={{ marginBottom: 20 }}>
        <Grid container>
          <Grid item xs={12} className="gridCenter">
            <img
              alt="Logo AIAMIS"
              className={classes.imgImersaoProfi}
              src={ImgImersao}
            />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={12} md={6} className="containerTwo">
            <Typography className="title">
              Plataforma de gestão de ambiente profissional
            </Typography>

            <Typography className="subtitle">
              Prezado estudante seja bem-vindo a plataforma de gestão de
              ambiente profissional, será através desse ambiente que você irá
              receber as orientações e iniciar as atividades em ambiente
              profissional. Para acessar favor realize o login com o seu
              registro acadêmico.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} className="container">

            <FormControl variant="outlined" fullWidth style={{ margin: 5 }}>
              <InputLabel htmlFor="outlined-adornment-password">R.A</InputLabel>
              <OutlinedInput
                id="outlined-adornment-username"
                value={raState}
                onChange={e => {
                  setRaState(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <AccountCircle style={{ color: '#555' }} />
                  </InputAdornment>
                }
                labelWidth={30}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth style={{ margin: 5 }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPasswordState ? 'text' : 'password'}
                value={passwordState}
                onChange={e => {
                  setPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPasswordState);
                      }}
                      onMouseDown={e => {
                        e.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPasswordState ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>

            <Box my={2}>
              <Button
                onClick={handleAuth}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                className="btnEntrar"
                disabled={loading}
              >
                {
                  loading ? (
                    <CircularProgress style={{ color: '#fff' }} />
                  ) : 'Entrar'
                }
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} style={{ marginTop: 20, textAlign: 'center' }}>
            <Typography
              style={{ color: '#444', fontSize: '0.7rem', fontWeight: 'bold' }}
            >
              Copyright &copy; DIRIN - PROEAD - AIAMIS - 2020.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default LoginView;
