import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import Delete from '@material-ui/icons/Delete';
import Check from '@material-ui/icons/Check';
import Error from '@material-ui/icons/Error';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';

import './styles.scss';

export default function ItemsFiles({ files, onDelete }) {
    return (
        <>
            {
                files.map((file) => {
                    return (
                        <Grid item xs={12} style={{ padding: 8 }} key={file?.id}>
                            <Box className="line_img_upload">
                                <Box className={`local_img ${file.name.split('.')[1] === 'pdf' || file?.ext === 'pdf' ? 'pdf' : ''}`}>
                                    {
                                        file.name.split('.')[1] === 'pdf' || file?.ext === 'pdf' ? (
                                            <Typography variant="subtitle2">PDF</Typography>
                                        ) : (
                                            <img
                                                src={file.preview}
                                                alt="Imagem Selecionada"
                                                style={{ width: '5rem', height: '5rem' }}
                                            />
                                        )
                                    }
                                </Box>

                                <Box className="local_details">
                                    <Typography className="filename" >{file.name}</Typography>
                                    <Typography className="file_length" >{file.size}</Typography>
                                    <Box display="flex" flexDirection="column" alignItems="center" >
                                        <Box width="100%">
                                            <LinearProgress color="secondary" variant="determinate" value={file.progress}/>
                                        </Box>

                                        <Box width="100%" marginTop={1} display="flex" justifyContent="flex-end" alignItems="center">
                                            {file.uploaded && (
                                                <Typography className="sended" title="Enviado" ><Check /></Typography>
                                            )}

                                            {file.error && (
                                                <Typography className="isError" title="Aconteceu um error"><Error /></Typography>
                                            )}
                                            <Typography variant="body2" style={{ color: '#616fbf', fontSize: 16, fontWeight: 'bold' }}>{`${file.progress}%`}</Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className="local_action">
                                    <IconButton
                                        title="Excluir Imagem"
                                        className={file.uploaded ? "iconCloseActive" : "iconClose"}
                                        disabled={!file.uploaded}
                                        onClick={() => onDelete(file.id)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                    )
                })
            }
        </>
    );
}