import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@material-ui/core';
import { CheckCircleOutline, Edit } from '@material-ui/icons';
import Page from 'src/components/Page';
import AuthContext from 'src/contexts/AuthContext';
import api from '../../services/api';
import { useSnackbar } from 'notistack';
import showSnack from 'src/utils/snacks';

import DialogTermoAceite from '../FormAcoes/components/DialogTermoAceite';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    background: '#eee',
    padding: 10,
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '-1px -9px 40px #888',
    marginBottom: 20
  },
  btnSeminario: {
    border: '1px solid #b00f14',
    borderRadius: 8,
    padding: 10,
    color: '#b00f14',
    '&:hover': {
      color: '#fff',
      background: '#b00f14',
      transition: 'background-color 0.5s',
      border: '2px solid #e5b31a'
    }
  },
  btnFinished: {
    borderRadius: 8,
    padding: 10,
    color: '#fff',
    background: '#0cb96e',
    '&:hover': {
      background: '#0f9058',
      transition: 'background-color 0.5s'
    }
  },
  btnEditar: {
    border: '2px solid #b00f14',
    borderRadius: 8,
    padding: 10,
    margin: 5,
    background: '#e5b31a',
    color: '#444',
    '&:hover': {
      background: '#c19716',
      transition: 'background-color 0.2s'
    }
  }
}));

const DetalhesCurso = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { ra } = useContext(AuthContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openTermoAceite, setOpenTermoAceite] = useState(false);
  const [idCarta, setIdCarta] = useState('');

  const course = sessionStorage.getItem('@c@cous@eea@');
  const [dados, setDados] = useState([]);

  const [verifica, setVerifica] = useState([{ id: -1, botao: null }]);

  useEffect(() => {
    if (ra) {
      api
        .post('seminarios/course-ra', {
          title_curso: course,
          ra
        })
        .then(({ data }) => {
          setDados(data);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, []);

  useEffect(() => {
    async function geraBotoes() {
      if (dados && dados.seminarios) {
        const botoes = verifica;
        await Promise.all(
          dados.seminarios.map(async dado => {
            await api
              .post('respostas/getByRaAndIdSeminario', {
                ra,
                id_seminario: dado.id
              })
              .then(({ data }) => {
                if (
                  data[0].questoes.length > 0 &&
                  data[0].seminario.cartaAutoriza[0]?.path_carta_autorizacao &&
                  data[0].seminario.cartaAutoriza[0]?.path_carta_autorizacao_ext
                ) {
                  botoes.push({
                    id: dado.id,
                    botao: (
                      <Grid
                        item
                        xs={12}
                        md={2}
                        style={{ padding: 10 }}
                        key={dado.id}
                      >
                        <Button
                          className={classes.btnFinished}
                          onClick={() => {
                            setIdCarta(
                              data[0].seminario.cartaAutoriza[0]?.id_carta
                            );
                            setOpen(true);
                          }}
                        >
                          {dado.titulo_seminario}
                        </Button>
                      </Grid>
                    )
                  });
                } else {
                  botoes.push({
                    id: dado.id,
                    botao: (
                      <Grid
                        item
                        xs={12}
                        md={2}
                        style={{ padding: 10 }}
                        key={dado.id}
                      >
                        <Button
                          className={classes.btnSeminario}
                          onClick={() => navigate(`/app/form-acoes/${dado.id}`)}
                        >
                          {dado.titulo_seminario}
                        </Button>
                      </Grid>
                    )
                  });
                }
              })
              .catch(e => console.log(e));
          })
        );
        botoes.sort((a, b) => a.id - b.id);
        setVerifica([...botoes]);
      }
    }
    geraBotoes();
  }, [dados.length]);

  return (
    <Page className={classes.root} title="Enviar Ações">
      <Container>
        <Grid container style={{ marginBottom: 20 }}>
          <Grid item xs={12} style={{ borderRadius: 8, textAlign: 'center' }}>
            <img
              src={dados.link_image_curso}
              style={{ height: '25rem', width: '100%' }}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid item xs={12} style={{ padding: 10, textAlign: 'center' }}>
            <Typography
              style={{
                textTransform: 'uppercase',
                color: '#b00f14',
                fontSize: '1.8rem',
                fontFamily: 'Lato',
                fontWeight: 'bold'
              }}
            >
              {course}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid
            item
            xs={12}
            style={{
              padding: 10,
              textAlign: 'center',
              borderBottom: '2px solid #b00f14'
            }}
          >
            <Typography
              style={{
                textTransform: 'uppercase',
                color: '#b00f14',
                fontSize: '1.8rem',
                fontFamily: 'Lato',
                fontWeight: 'bold'
              }}
            >
              Envio das ações realizadas em ambiente profissional
            </Typography>
          </Grid>

          {verifica.map(dado => dado.botao)}

          <Grid
            item
            xs={12}
            style={{
              marginTop: 20,
              textAlign: 'center',
              borderTop: '1px dashed #bbb'
            }}
          >
            <Typography
              style={{ color: '#444', fontSize: '0.7rem', fontWeight: 'bold' }}
            >
              Copyright &copy; DIRIN - PROEAD - AIAMIS - 2020.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={open}
        onClose={() => {
          setIdCarta('');
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Escolha uma ação!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você já realizou o envio deste formulário. Agora você pode:
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            color="primary"
            startIcon={<Edit />}
            className={classes.btnEditar}
            onClick={() => {
              setOpenTermoAceite(true);
            }}
          >
            Alterar Termo de Aceite
          </Button>
        </DialogActions>
      </Dialog>

      <DialogTermoAceite
        open={openTermoAceite}
        setOpen={setOpenTermoAceite}
        idCarta={idCarta}
        verificaCarta={true}
      />
    </Page>
  );
};

export default DetalhesCurso;
