import {
  Button,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import AuthContext from 'src/contexts/AuthContext';
import showSnack from 'src/utils/snacks';
import api from '../../services/api';
import DialogPdfOrEditPortifolio from './components/DialogPdfOrEditPortifolio';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    background: '#eee',
    padding: 10,
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '-1px -9px 40px #888',
    marginBottom: 20
  },
  btnSeminario: {
    border: '1px solid #b00f14',
    borderRadius: 8,
    padding: 10,
    color: '#b00f14',
    '&:hover': {
      color: '#fff',
      background: '#b00f14',
      transition: 'background-color 0.5s',
      border: '2px solid #e5b31a'
    }
  },
  btnFinished: {
    borderRadius: 8,
    padding: 10,
    color: '#fff',
    background: '#0cb96e',
    '&:hover': {
      background: '#0f9058',
      transition: 'background-color 0.5s'
    }
  },
  btnPdf: {
    border: '2px solid #e5b31a',
    borderRadius: 8,
    margin: 5,
    padding: 10,
    color: '#fff',
    background: '#b00f14',
    '&:hover': {
      color: '#fff',
      background: '#850a0e',
      transition: 'background-color 0.5s'
    }
  },
  btnEditar: {
    border: '2px solid #b00f14',
    borderRadius: 8,
    padding: 10,
    margin: 5,
    background: '#e5b31a',
    color: '#444',
    '&:hover': {
      background: '#c19716',
      transition: 'background-color 0.2s'
    }
  }
}));

const ListSeminarioPortifolio = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { ra } = useContext(AuthContext);
  const navigate = useNavigate();
  const course = sessionStorage.getItem('@c@cous@eea@');

  const [dados, setDados] = useState([]);
  const [openChoice, setOpenChoice] = useState(false);

  const [idSeminario, setIdSeminario] = useState('');
  const [portfolioPdf, setPortfolioPdf] = useState();

  useEffect(() => {
    if (!!idSeminario && !!ra)
      api
        .post('portifolio/getPDF', {
          id_seminario: idSeminario,
          ra
        })
        .then(({ data }) => {
          setPortfolioPdf(data);

          // navigate('/app/form-portifolio/meu-portifolio', { state: { ...data } });
        })
        .catch(() => {
          showSnack('Oops! Ocorreu um erro!', enqueueSnackbar, 'error');
        });
  }, [idSeminario, ra]);

  useEffect(() => {
    if (ra) {
      api
        .post('seminarios/course-ra', {
          title_curso: course,
          ra
        })
        .then(({ data }) => {
          setDados(data);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, []);

  return (
    <Page className={classes.root} title="Enviar Ações">
      <Container>
        <Grid container style={{ marginBottom: 20 }}>
          <Grid item xs={12} style={{ borderRadius: 8, textAlign: 'center' }}>
            <img
              src={dados.link_image_curso}
              style={{ height: '25rem', width: '100%' }}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid item xs={12} style={{ padding: 10, textAlign: 'center' }}>
            <Typography
              style={{
                textTransform: 'uppercase',
                color: '#b00f14',
                fontSize: '1.8rem',
                fontFamily: 'Lato',
                fontWeight: 'bold'
              }}
            >
              {course}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid
            item
            xs={12}
            style={{
              padding: 10,
              textAlign: 'center',
              borderBottom: '2px solid #b00f14'
            }}
          >
            <Typography
              style={{
                textTransform: 'uppercase',
                color: '#b00f14',
                fontSize: '1.8rem',
                fontFamily: 'Lato',
                fontWeight: 'bold'
              }}
            >
              Construção de portifolio por seminário
            </Typography>
          </Grid>

          {dados.seminarios?.map(data => {
            if (data.portfolio) {
              return (
                <Grid item xs={12} md={2} style={{ padding: 10 }} key={data.id}>
                  <Button
                    className={classes.btnFinished}
                    onClick={() => {
                      setIdSeminario(data.id);
                      setOpenChoice(true);
                    }}
                  >
                    {data.titulo_seminario}
                  </Button>
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12} md={2} style={{ padding: 10 }} key={data.id}>
                  <Button
                    className={classes.btnSeminario}
                    onClick={() => {
                      navigate(`/app/form-portifolio/${data.id}`);
                    }}
                  >
                    {data.titulo_seminario}
                  </Button>
                </Grid>
              );
            }
          })}

          <Grid
            item
            xs={12}
            style={{
              marginTop: 20,
              textAlign: 'center',
              borderTop: '1px dashed #bbb'
            }}
          >
            <Typography
              style={{ color: '#444', fontSize: '0.7rem', fontWeight: 'bold' }}
            >
              Copyright &copy; DIRIN - PROEAD - AIAMIS - 2020.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <DialogPdfOrEditPortifolio
        idSeminario={idSeminario}
        title="Escolha uma ação!"
        description="Você já realizou a construção deste portifolio. Agora você pode:"
        open={openChoice}
        onClosed={() => {
          setIdSeminario('');
          setOpenChoice(false);
        }}
        onActionButtonEdit={() => {
          setOpenChoice(false);
          navigate(`/app/edit-portifolio/${idSeminario}`);
        }}
      />
    </Page>
  );
};

export default ListSeminarioPortifolio;
