import React, { useCallback, useState } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { Close, Description } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import showSnack from '../../utils/snacks';

import './styles.scss';

export default function SeletorArquivos({ state, setState, setOpen, type = 'registro_ft' }) {
  const { enqueueSnackbar } = useSnackbar();

  const [fileSelected, setFileSelected] = useState();
  
  const onDrop = useCallback((acceptedFiles) => {
    let arraySelected = [];
    let files = [];

    if (acceptedFiles.length > 0) { 
      if (state.length > 0) {
        acceptedFiles.map(data => {
          let pass = false;
  
          state.map(file => {
            if (file.name === data.name) {
              pass = true;
            }
          });

          if (!pass) {
            arraySelected.push(data);
          }
        });
      } else {
        arraySelected = acceptedFiles;
      }
      
      files = [...state, ...arraySelected];

      if (state.length >= 4 && type === 'registro_ft') {
        showSnack('Você só pode selecionar 4 arquivos', enqueueSnackbar, 'warning');
      } else {
        setFileSelected(files);
        setState(files);
      }
      
      setOpen(false); 
    }

  }, []);
  
  const onDropRejected = useCallback((e) => {
    e[0].errors.map((error) => {
      console.log(error);
      if (error.code === 'file-too-large') {
        alert('O arquivo enviado supera o limite de 45MB');
      }
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: type === 'registro_ft' ? 4 : 0,
    maxSize: 45 * 1024 * 1024,
    multiple: true,
    onDropRejected,
    accept: type === 'registro_ft' ? ['.png', '.jpeg', '.jpg'] : ['.pdf', '.png', '.jpeg', '.jpg']
  });

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    paddingTop: '30px',
    paddingBottom: '30px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#b0b0b0',
    borderStyle: 'dashed',
    backgroundColor: '#e0e0e0',
    color: '#000000aa',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };
  const activeStyle = {
    borderColor: '#2196f3',
  };
  const acceptStyle = {
    borderColor: '#00e676',
  };
  const rejectStyle = {
    borderColor: '#ff1744',
  };

  return (
    <div id="container-seletor-arquivo">
      <div
        {...getRootProps({
          style: {
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
          },
        })}
      >
        {!fileSelected ? (
          <>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Solte o arquivo aqui.</p>
            ) : (
              <p id="mensagem-arquivo">
                Arraste e solte seus arquivos aqui
                <span>Ou</span>
                <Button variant="contained">
                  Clique para selecionar os arquivos
                </Button>
                <span>Tamanho máximo: 5Mb</span>
              </p>
            )}
          </>
        ) : (
          <div id="container-arquivo-selecionado">
            <Description fontSize="large" style={{ color: '#00e676' }} />{' '}
            <div id="arquivo-selecionado">
              {fileSelected.name}
              <IconButton
                onClick={() => {
                  setFileSelected(null);
                }}
                color="primary"
              >
                <Close />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
