import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormLabel,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CircularProgress,
  CardContent,
  Box,
  IconButton,
  CardActions
} from '@material-ui/core';
import Page from 'src/components/Page';
import { Check, Home, Close, Description } from '@material-ui/icons';
import ReactQuill from 'react-quill';
import { useSnackbar } from 'notistack';
import showSnack from '../../utils/snacks';
import AuthContext from 'src/contexts/AuthContext';
import api from '../../services/api';

import SeletorArquivos from '../../components/SeletorArquivos';
import DialogRegistrosFotograficos from './components/DialogRegistrosFotograficos';
import DialogTermosUsoImage from './components/DialogTermosUsoImage';
import { SEMINARIOS } from '../PortifolioForm';
import apiIbge from 'src/services/apiIbge';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  appBar: {
    position: 'relative'
  },
  imgLogin: {
    [theme.breakpoints.down('sm')]: {
      height: 100,
      width: 100
    },
    [theme.breakpoints.up('sm')]: {
      height: 150,
      width: 190
    }
  },
  container: {
    background: '#eee',
    padding: 10,
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '-1px -9px 40px #888',
    marginBottom: 20
  },
  gridCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px dashed #bbb'
  },
  gridQuestion: {
    padding: 15
  },
  question: {
    color: '#444',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  answer: {
    paddingLeft: 50
  },
  qtAberta: {
    '&:focus': {
      outlineColor: '#b00f14'
    },
    width: '95%',
    border: '1px solid #ddd',
    borderRadius: 8,
    background: '#ddd',
    padding: 10,
    fontSize: 16,
    resize: 'none',
    fontFamily: 'Roboto'
  },
  btnSalvar: {
    margin: 5,
    backgroundColor: '#b00f14',
    color: '#fff',
    '&:hover': {
      background: '#820d10',
      transition: 'background-color 0.5s'
    }
  },
  btnVoltar: {
    margin: 5,
    backgroundColor: '#e5b31a',
    color: '#444',
    '&:hover': {
      backgroundColor: '#c19716',
      transition: 'background-color 0.2s'
    }
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%',
    background: '#fff'
  },
  formLabel: {
    fontWeight: 'bold',
    color: '#b00f14'
  },
  teste: {
    background: 'red'
  },
  inputFile: {
    display: 'none'
  },
  labelFile: {
    width: '100%',
    padding: '10px 5px',
    backgroundColor: '#e5b31a',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    marginTop: '10px',
    cursor: 'pointer',
    margin: 10,
    borderRadius: 8,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#c19716',
      transition: 'background-color 0.2s'
    }
  },
  labelFileDisabled: {
    width: '100%',
    padding: '10px 5px',
    backgroundColor: '#756a6a',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    marginTop: '10px',
    cursor: 'pointer',
    margin: 10,
    borderRadius: 8,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#544d4d',
      transition: 'background-color 0.2s'
    }
  },
  btnExcluir: {
    background: 'transparent',
    '&:hover': {
      background: 'transparent'
    },
    '&:click': {
      background: 'transparent'
    }
  },
  editorText: {
    background: '#fff',
    borderRadius: 8,
    border: '1px solid #ccc',
    height: '200px'
  },
  areaFieldset: {
    padding: 4,
    border: '1px solid #444',
    width: '100%',
    margin: '10px 0px',
    borderRadius: 4,
    background: '#dedede'
  },
  legendTitle: {
    padding: '0px 8px',
    textTransform: 'uppercase',
    fontFamily: 'roboto',
    fontWeight: 700,
    fontSize: 12,
    color: '#222'
  }
}));

const PortifolioForm = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { ra } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const idSeminario = useParams().id_seminario;
  const course = sessionStorage.getItem('@c@cous@eea@');
  const estados = [
    { sigla: 'FL', nome: 'Flórida - EUA' },
    { sigla: 'AC', nome: 'Acre' },
    { sigla: 'AL', nome: 'Alagoas' },
    { sigla: 'AM', nome: 'Amazonas' },
    { sigla: 'AP', nome: 'Amapá' },
    { sigla: 'BA', nome: 'Bahia' },
    { sigla: 'CE', nome: 'Ceará' },
    { sigla: 'DF', nome: 'Distrito Federal' },
    { sigla: 'ES', nome: 'Espírito Santo' },
    { sigla: 'GO', nome: 'Goiás' },
    { sigla: 'MA', nome: 'Maranhão' },
    { sigla: 'MG', nome: 'Minas Gerais' },
    { sigla: 'MS', nome: 'Mato Grosso do Sul' },
    { sigla: 'MT', nome: 'Mato Grosso' },
    { sigla: 'PA', nome: 'Pará' },
    { sigla: 'PB', nome: 'Paraíba' },
    { sigla: 'PE', nome: 'Pernambuco' },
    { sigla: 'PI', nome: 'Piauí' },
    { sigla: 'PR', nome: 'Paraná' },
    { sigla: 'RJ', nome: 'Rio de Janeiro' },
    { sigla: 'RN', nome: 'Rio Grande do Norte' },
    { sigla: 'RO', nome: 'Rondônia' },
    { sigla: 'RR', nome: 'Roraima' },
    { sigla: 'RS', nome: 'Rio Grande do Sul' },
    { sigla: 'SE', nome: 'Sergipe' },
    { sigla: 'SC', nome: 'Santa Catarina' },
    { sigla: 'SP', nome: 'São Paulo' },
    { sigla: 'TO', nome: 'Tocantins' }
  ];

  const [isSeminarThree, setIsSeminarThree] = useState(false);

  const [recarregar, setRecarregar] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingComponentRegistro, setLoadingComponentRegistro] = useState(
    false
  );
  const [loadingComponentTermo, setLoadingComponentTermo] = useState(false);

  const [openSelecionarTermos, setOpenSelecionarTermos] = useState(false);
  const [openVisualizarImagens, setOpenVisualizarImagens] = useState(false);
  const [openVisualizarTermos, setOpenVisualizarTermos] = useState(false);

  const [idPortifolio, setIdPortifolio] = useState('');
  const [nomeEmpresaVisitada, setNomeEmpresaVisitada] = useState('');
  const [dataVisita, setDataVisita] = useState('');
  const [enderecoEmpresaVisitada, setEnderecoEmpresaVisitada] = useState('');
  const [cidadeEmpresaVisitada, setCidadeEmpresaVisitada] = useState('');
  const [estadoEmpresaVisitada, setEstadoEmpresaVisitada] = useState('');
  const [
    horarioChegadaEmpresaVisitada,
    setHorarioChegadaEmpresaVisitada
  ] = useState('');
  const [horarioTerminoVisita, setHorarioTerminoVisita] = useState('');
  const [imagens, setImagens] = useState([]);
  const [imagensCadastradas, setImagensCadastradas] = useState([]);
  const [termos, setTermos] = useState([]);
  const [termosCadastradas, setTermosCadastradas] = useState([]);

  const [openConfirm, setConfirm] = useState(false);
  const [openLimit, setOpenLimit] = useState(false);
  const [textLimit, setTextLimit] = useState('');

  const [cidades, setCidades] = useState([]);
  const [estado, setEstado] = useState('');

  const [apresentacao, setApresentacao] = useState(0);
  const [apresentacaoLength, setApresentacaoLength] = useState(0);
  const [atividadesRealizadas, setAtividadesRealizadas] = useState(0);
  const [atividadesRealizadasLength, setAtividadesRealizadasLength] = useState(
    0
  );
  const [setoresVisitados, setSetoresVisitados] = useState(0);
  const [setoresVisitadosLength, setSetoresVisitadosLength] = useState(0);
  const [desenvolvimento, setDesenvolvimento] = useState(0);
  const [desenvolvimentoLength, setDesenvolvimentoLength] = useState(0);
  const [conclusao, setConclusao] = useState(0);
  const [conclusaoLength, setConclusaoLength] = useState(0);

  const salvar = () => {
    if (
      idPortifolio &&
      ra &&
      idSeminario &&
      nomeEmpresaVisitada &&
      enderecoEmpresaVisitada &&
      cidadeEmpresaVisitada &&
      estadoEmpresaVisitada &&
      conclusao
    ) {
      let process = true;

      if (!isSeminarThree) {
        if (
          dataVisita &&
          apresentacao &&
          horarioChegadaEmpresaVisitada &&
          setoresVisitados &&
          atividadesRealizadas &&
          horarioTerminoVisita &&
          desenvolvimento
        ) {
          process = true;
        } else {
          process = false;
          showSnack('Preencha todos campos!', enqueueSnackbar, 'warning');
        }
      }

      if (process) {
        api
          .put('portifolio/1', {
            id: idPortifolio,
            ra,
            id_seminario: idSeminario,
            data_visita: dataVisita,
            nome_empresa_visitada: nomeEmpresaVisitada,
            endereco_empresa_visitada: enderecoEmpresaVisitada,
            cidade_empresa_visitada: cidadeEmpresaVisitada,
            estado_empresa_visitada: estadoEmpresaVisitada,
            apresentacao,
            horario_chegada_empresa_visitada: horarioChegadaEmpresaVisitada,
            setores_visitados: setoresVisitados,
            atividades_realizadas: atividadesRealizadas,
            horario_termino_visita: horarioTerminoVisita,
            desenvolvimento,
            conclusao
          })
          .then(({ data }) => {
            if (data.success) {
              showSnack('Portifolio atualizado com sucesso!', enqueueSnackbar);
              setRecarregar(!recarregar);
              setConfirm(false);
            }
          })
          .catch(e =>
            showSnack(
              'Oops! Ocorreu um erro ao realizar o envio!',
              enqueueSnackbar,
              'error'
            )
          );
      }
    } else {
      showSnack('Preencha todos campos!', enqueueSnackbar, 'warning');
    }
  };
  const getSeminarioById = (id, seminarios) => {
    return seminarios.find(seminario => seminario.id === Number(id));
  };
  useEffect(() => {
    if (ra) {
      setLoadingPage(true);
      api
        .post('seminarios/course-ra', {
          title_curso: course,
          ra
        })
        .then(({ data }) => {
          const seminario = getSeminarioById(idSeminario, data.seminarios);
          if (seminario && seminario.portfolio) {
            if (SEMINARIOS.includes(seminario.cod)) {
              setIsSeminarThree(true);
            } else {
              setIsSeminarThree(false);
            }

            setNomeEmpresaVisitada(
              seminario.portfolio?.nome_empresa_visitada
            );
            setEnderecoEmpresaVisitada(
              seminario.portfolio?.endereco_empresa_visitada
            );
            setEstadoEmpresaVisitada(
              seminario.portfolio?.estado_empresa_visitada
            );
            setCidadeEmpresaVisitada(
              seminario.portfolio?.cidade_empresa_visitada
            );
            setDataVisita(seminario.portfolio?.data_visita);
            setHorarioChegadaEmpresaVisitada(
              seminario.portfolio?.horario_chegada_empresa_visitada
            );
            setHorarioTerminoVisita(
              seminario.portfolio?.horario_termino_visita
            );

            setApresentacao(seminario.portfolio?.apresentacao);
            setAtividadesRealizadas(
              seminario.portfolio?.atividades_realizadas
            );
            setSetoresVisitados(
              seminario.portfolio?.setores_visitados
            );
            setDesenvolvimento(seminario.portfolio?.desenvolvimento);
            setConclusao(seminario.portfolio?.conclusao);

            estados.map(estados => {
              if (
                seminario.portfolio.estado_empresa_visitada ===
                estados.nome
              ) {
                setEstado(estados.sigla);
                if (estados.sigla === 'FL') {
                  setCidades([{ nome: 'Polo Orlando' }]);
                } else {
                  apiIbge
                    .get(`${estados.sigla}/municipios`)
                    .then(({ data }) => {
                      setCidades(data);
                    })
                    .catch(e => console.log(e));
                }
              }
            });

            api
              .get(`portifolio/${seminario.portfolio.id}`)
              .then(({ data }) => {
                setImagensCadastradas(data.imagens);
                setTermosCadastradas(data.termos);
                setLoadingPage(false);
              })
              .catch(e => {
                console.log(e);
              });

            setIdPortifolio(seminario.portfolio.id);
          } else {
            navigate('/app/list-portifolio');
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [recarregar]);

  return (
    <Page className={classes.root} title="Formulário de Portifolio">
      <Container>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.gridCenter}>
            <img
              alt="Logo AIAMIS"
              className={classes.imgLogin}
              src="/favicon.png"
            />

            <Typography
              style={{
                color: '#444',
                fontSize: '1.8rem',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              Construção do Portifolio
            </Typography>
          </Grid>

          {loadingPage ? (
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: 20,
                  gap: 10
                }}
              >
                <Typography
                  style={{ color: '#b00f14', fontWeight: 'bold', fontSize: 18 }}
                >
                  Por favor, aguarde...
                </Typography>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              <fieldset className={classes.areaFieldset}>
                <legend className={classes.legendTitle}>
                  Empresa/Instituição Visitada
                </legend>
                <Grid container>
                  <Grid item xs={12} style={{ padding: 10 }}>
                    <FormLabel className={classes.formLabel}>
                      Empresa/Instituição visitada:
                    </FormLabel>
                    <TextField
                      className={classes.textField}
                      id="nome_empresa_visitada"
                      placeholder="Digite o nome da Empresa/Instituição visitada "
                      variant="outlined"
                      type="text"
                      onChange={e => {
                        setNomeEmpresaVisitada(e.target.value);
                      }}
                      value={nomeEmpresaVisitada}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} style={{ padding: 10 }}>
                    <FormLabel className={classes.formLabel}>
                      Endereço:
                    </FormLabel>
                    <TextField
                      className={classes.textField}
                      id="endereco_empresa_visitada"
                      placeholder="Digite o endereço da instituição"
                      variant="outlined"
                      type="text"
                      onChange={e => {
                        setEnderecoEmpresaVisitada(e.target.value);
                      }}
                      value={enderecoEmpresaVisitada}
                    />
                  </Grid>

                  <Grid item xs={12} md={2} style={{ padding: 10 }}>
                    <FormLabel className={classes.formLabel}>Estado:</FormLabel>
                    <FormControl
                      variant="outlined"
                      className={classes.textField}
                    >
                      <Select
                        id="estado_empresa_visitada"
                        onChange={e => {
                          setEstado(e.target.value);
                          setCidadeEmpresaVisitada('');
                          if (e.target.value === 'FL') {
                            setEstadoEmpresaVisitada('Flórida - EUA');
                            setCidades([{ nome: 'Polo Orlando' }]);
                          }
                          apiIbge.get(`${e.target.value}/municipios`)
                            .then(({ data }) => {
                              setEstadoEmpresaVisitada(
                                data[0].microrregiao.mesorregiao.UF.nome
                              );
                              setCidades(data);
                            })
                            .catch(e => console.log(e));
                        }}
                        value={estado}
                      >
                        <MenuItem value="" disabled>
                          <em>Selecione...</em>
                        </MenuItem>
                        {estados.map((estados, index) => {
                          return (
                            <MenuItem key={index} value={estados.sigla}>
                              {estados.nome}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} style={{ padding: 10 }}>
                    <FormLabel className={classes.formLabel}>Cidade:</FormLabel>
                    <FormControl
                      variant="outlined"
                      className={classes.textField}
                    >
                      <Select
                        id="cidade_empresa_visitada"
                        onChange={e => {
                          setCidadeEmpresaVisitada(e.target.value);
                        }}
                        value={cidadeEmpresaVisitada}
                      >
                        <MenuItem value="" disabled>
                          <em>Selecione...</em>
                        </MenuItem>
                        {cidades.map((cidades, index) => {
                          return (
                            <MenuItem key={index} value={cidades.nome}>
                              {cidades.nome}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </fieldset>

              {!isSeminarThree && (
                <fieldset className={classes.areaFieldset}>
                  <legend className={classes.legendTitle}>
                    Data/Hora Visita
                  </legend>
                  <Grid container>
                    <Grid item xs={12} md={4} style={{ padding: 10 }}>
                      <FormLabel className={classes.formLabel}>Data:</FormLabel>
                      <TextField
                        id="data_visita"
                        type="date"
                        variant="outlined"
                        className={classes.textField}
                        onChange={e => {
                          setDataVisita(e.target.value);
                        }}
                        value={dataVisita?.split('T')[0]}
                      />
                    </Grid>

                    <Grid item xs={12} md={4} style={{ padding: 10 }}>
                      <FormLabel className={classes.formLabel}>
                        Horário de chegada:
                      </FormLabel>
                      <TextField
                        id="horario_chegada_empresa_visitada"
                        type="time"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          step: 300 // 5 min
                        }}
                        variant="outlined"
                        onChange={e => {
                          setHorarioChegadaEmpresaVisitada(e.target.value);
                        }}
                        value={horarioChegadaEmpresaVisitada}
                      />
                    </Grid>

                    <Grid item xs={12} md={4} style={{ padding: 10 }}>
                      <FormLabel className={classes.formLabel}>
                        Horário de término da visita:
                      </FormLabel>
                      <TextField
                        id="horario_termino_visita"
                        type="time"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          step: 300 // 5 min
                        }}
                        variant="outlined"
                        onChange={e => {
                          setHorarioTerminoVisita(e.target.value);
                        }}
                        value={horarioTerminoVisita}
                      />
                    </Grid>
                  </Grid>
                </fieldset>
              )}

              <fieldset className={classes.areaFieldset}>
                <legend className={classes.legendTitle}>
                  Relatório Visita
                </legend>
                <Grid container>
                  {!isSeminarThree && (
                    <>
                      <Grid
                        item
                        xs={12}
                        style={{ padding: 10, marginBottom: 10 }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <FormLabel className={classes.formLabel}>
                            Apresentação:
                          </FormLabel>
                          <Typography
                            style={{
                              textAlign: 'right',
                              fontSize: 12,
                              fontWeight: 'bold',
                              color: '#444'
                            }}
                          >
                            {apresentacaoLength
                              ? apresentacaoLength - 1
                              : apresentacaoLength}{' '}
                            de 2500 caracteres
                          </Typography>
                        </Grid>

                        <ReactQuill
                          className={classes.editorText}
                          theme={false}
                          value={apresentacao}
                          placeholder="Faça um breve relato sobre as empresa/ instituição, o seu histórico, a sua missão e princípios (se houver), onde se localiza, o ramo de atividade, mercado a onde atua, e outras informações que puder coletar."
                          onChange={(content, delta, source, editor) => {
                            if (editor.getLength() - 1 > 2500) {
                              setTextLimit(apresentacao);
                              setOpenLimit(true);
                            } else {
                              setApresentacao(content);
                            }
                            setApresentacaoLength(editor.getLength());
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{ padding: 10, marginBottom: 10 }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <FormLabel className={classes.formLabel}>
                            Atividades Realizadas:
                          </FormLabel>
                          <Typography
                            style={{
                              textAlign: 'right',
                              fontSize: 12,
                              fontWeight: 'bold',
                              color: '#444'
                            }}
                          >
                            {atividadesRealizadasLength
                              ? atividadesRealizadasLength - 1
                              : atividadesRealizadasLength}{' '}
                            de 2500 caracteres
                          </Typography>
                        </Grid>

                        <ReactQuill
                          className={classes.editorText}
                          theme={false}
                          value={atividadesRealizadas}
                          placeholder="Digite o nome das atividades"
                          onChange={(content, delta, source, editor) => {
                            if (editor.getLength() - 1 > 2500) {
                              setTextLimit(atividadesRealizadas);
                              setOpenLimit(true);
                            } else {
                              setAtividadesRealizadas(content);
                            }
                            setAtividadesRealizadasLength(editor.getLength());
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{ padding: 10, marginBottom: 10 }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <FormLabel className={classes.formLabel}>
                            Setores Visitados:
                          </FormLabel>
                          <Typography
                            style={{
                              textAlign: 'right',
                              fontSize: 12,
                              fontWeight: 'bold',
                              color: '#444'
                            }}
                          >
                            {setoresVisitadosLength
                              ? setoresVisitadosLength - 1
                              : setoresVisitadosLength}{' '}
                            de 2500 caracteres
                          </Typography>
                        </Grid>

                        <ReactQuill
                          className={classes.editorText}
                          theme={false}
                          value={setoresVisitados}
                          placeholder="Digite o nome do setores visitados"
                          onChange={(content, delta, source, editor) => {
                            if (editor.getLength() - 1 > 2500) {
                              setTextLimit(setoresVisitados);
                              setOpenLimit(true);
                            } else {
                              setSetoresVisitados(content);
                            }
                            setSetoresVisitadosLength(editor.getLength());
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{ padding: 10, marginBottom: 10 }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <FormLabel className={classes.formLabel}>
                            Desenvolvimento:
                          </FormLabel>
                          <Typography
                            style={{
                              textAlign: 'right',
                              fontSize: 12,
                              fontWeight: 'bold',
                              color: '#444'
                            }}
                          >
                            {desenvolvimentoLength
                              ? desenvolvimentoLength - 1
                              : desenvolvimentoLength}{' '}
                            de 2500 caracteres
                          </Typography>
                        </Grid>

                        <ReactQuill
                          className={classes.editorText}
                          theme={false}
                          value={desenvolvimento}
                          placeholder="Faça um relato sobre como foi a sua visita e descreva suas observações sobre o ambiente profissional visitado externo e internamente, setores, profissionais, atividades realizadas e se existem algum projeto ou programa de melhoria interna das atividades e serviços prestados"
                          onChange={(content, delta, source, editor) => {
                            if (editor.getLength() - 1 > 2500) {
                              setTextLimit(desenvolvimento);
                              setOpenLimit(true);
                            } else {
                              setDesenvolvimento(content);
                            }
                            setDesenvolvimentoLength(editor.getLength());
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} style={{ padding: 10, marginBottom: 10 }}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <FormLabel className={classes.formLabel}>
                        Conclusão:
                      </FormLabel>
                      <Typography
                        style={{
                          textAlign: 'right',
                          fontSize: 12,
                          fontWeight: 'bold',
                          color: '#444'
                        }}
                      >
                        {conclusaoLength
                          ? conclusaoLength - 1
                          : conclusaoLength}{' '}
                        de 2500 caracteres
                      </Typography>
                    </Grid>

                    <ReactQuill
                      className={classes.editorText}
                      theme={false}
                      value={conclusao}
                      placeholder="Descreva a relevância da visita e porque, aspectos que lhe surpreenderam, poderá descrever aspectos negativos ou positivos. Realize outros comentários que julgar necessário"
                      onChange={(content, delta, source, editor) => {
                        if (editor.getLength() - 1 > 2500) {
                          setTextLimit(conclusao);
                          setOpenLimit(true);
                        } else {
                          setConclusao(content);
                        }
                        setConclusaoLength(editor.getLength());
                      }}
                    />
                  </Grid>
                </Grid>
              </fieldset>

              <fieldset className={classes.areaFieldset}>
                <legend className={classes.legendTitle}>
                  Registros da Visita
                </legend>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} md={6} style={{ padding: 8 }}>
                      <Card>
                        <CardHeader
                          title={
                            <Typography
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                textAlign: 'center'
                              }}
                              color="primary"
                            >
                              Registros Fotográficos
                            </Typography>
                          }
                        />

                        {loadingComponentRegistro ? (
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <CircularProgress />
                          </div>
                        ) : (
                          imagens.length > 0 && (
                            <CardContent>
                              {imagens?.map((img, index) => {
                                return (
                                  <Box
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                      gap: 20
                                    }}
                                  >
                                    <Description style={{ color: '#00e676' }} />
                                    {img.name}
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        setLoadingComponentRegistro(true);
                                        imagens.splice(index, 1);

                                        setImagens(imagens);
                                        setTimeout(() => {
                                          setLoadingComponentRegistro(false);
                                        }, 10);
                                      }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </Box>
                                );
                              })}
                            </CardContent>
                          )
                        )}

                        <CardActions>
                          <Grid container>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                style={{ padding: 16, margin: 5 }}
                                onClick={() => {
                                  setOpenVisualizarImagens(true);
                                }}
                              >
                                Editar Imagens
                              </Button>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6} style={{ padding: 8 }}>
                      <Card>
                        <CardHeader
                          title={
                            <Typography
                              style={{
                                fontSize: 20,
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                textAlign: 'center'
                              }}
                              color="primary"
                            >
                              Termos de cessão de direito de uso de imagem
                            </Typography>
                          }
                        />

                        <CardActions>
                          <Grid container>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                style={{ padding: 16, margin: 5 }}
                                onClick={() => {
                                  setOpenVisualizarTermos(true);
                                }}
                              >
                                Editar Termos
                              </Button>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </fieldset>
            </>
          )}

          <Grid
            item
            xs={12}
            style={{ textAlign: 'center', borderTop: '1px dashed #bbb' }}
          >
            <Button
              className={classes.btnVoltar}
              onClick={() => navigate('/app')}
            >
              <Home />
              &nbsp;&nbsp; Início
            </Button>
            <Button
              className={classes.btnSalvar}
              onClick={() => setConfirm(true)}
            >
              <Check />
              &nbsp;&nbsp; Salvar
            </Button>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 20, textAlign: 'center' }}>
            <Typography
              style={{ color: '#444', fontSize: '0.7rem', fontWeight: 'bold' }}
            >
              Copyright &copy; DIRIN - PROEAD - AIAMIS - 2020.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={openConfirm}
        onClose={() => setConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            style={{ color: '#b00f14', fontWeight: 'bold', fontSize: '1.2rem' }}
          >
            Confirma o envio ?
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Typography
            style={{ color: '#444', fontWeight: 'bold', fontSize: '0.9rem' }}
          >
            Antes de confirmar o envio, recomendamos que confira o que está
            sendo enviado!
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => salvar()} className={classes.btnSalvar}>
            Enviar!
          </Button>
          <Button
            variant="outlined"
            onClick={() => setConfirm(false)}
            color="primary"
          >
            Fechar!
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openLimit}
        onClose={() => setOpenLimit(false)}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="paper"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{ background: '#b00f14', borderBottom: '5px solid #e5b31a' }}
        >
          <Typography
            style={{
              textAlign: 'center',
              color: '#fff',
              fontSize: '1.2rem',
              fontWeight: 'bold'
            }}
          >
            O limite de caracteres foi alcançado!
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{ fontFamily: 'Roboto' }}>
          <Typography
            style={{ textAlign: 'center', color: '#777', fontWeight: 'bold' }}
          >
            O texto que será enviado:
          </Typography>
          {textLimit ? parse(textLimit) : ''}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenLimit(false)}
            className={classes.btnSalvar}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Visualizar Imagens */}
      <DialogRegistrosFotograficos
        imagensCadastradas={imagensCadastradas}
        idPortifolio={idPortifolio}
        open={openVisualizarImagens}
        setOpen={setOpenVisualizarImagens}
      />

      {/* Visualizar Termos */}
      <DialogTermosUsoImage
        termosCadastradas={termosCadastradas}
        idPortifolio={idPortifolio}
        open={openVisualizarTermos}
        setOpen={setOpenVisualizarTermos}
      />
    </Page>
  );
};

export default PortifolioForm;
