import React from 'react';
import api from '../services/api';
import { use } from 'react-router-dom';
import AuthContext from './AuthContext';

export default function AuthProvider({ children }) {
  const [isAuth, setIsAuth] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [ra, setRa] = React.useState('');

  const updateRA = React.useCallback(() => {
    if (ra) {
      sessionStorage.setItem('@u@ap@aut@r@a', ra);
      api
        .post('isAuth', { username: ra })
        .then(r => {
          setIsAuth(r.data.isAuth);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          sessionStorage.removeItem('@c@cous@eea@');
        });
    }
  }, [ra]);

  React.useEffect(() => {
    setLoading(true);
    updateRA();
  }, [ra]);

  React.useEffect(() => {
    const raLocal = ra ? ra : sessionStorage.getItem('@u@ap@aut@r@a');
    if (raLocal) {
      api.post('isAuth', { username: raLocal }).then(r => {
        setIsAuth(r.data.isAuth);
        setLoading(false);
        setRa(raLocal);
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuth, loading, ra, setRa }}>
      {children}
    </AuthContext.Provider>
  );
}
