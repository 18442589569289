import React, { useState } from 'react';
import {
    Grid,
    Radio,
    FormControl,
    RadioGroup,
    FormControlLabel,
} from '@material-ui/core';

const SelectRadio = ({ alternativas = [], setValor, index, tipoQuestao, idQuestao }) => {
    const [state, setState] = useState();

    return (
        <>  
            <Grid item xs={12} className="answer">
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="position"
                        name="position"
                        defaultValue="top"
                        value={state ? state : ''}
                        onChange={(e) => {
                            setState(e.target.value);
                            setValor({['questao'+index]: {value: e.target.value, tipo_questao: tipoQuestao, id: idQuestao}});
                        }}
                    >
                        {
                            alternativas.map(alt => {
                                return (
                                    <FormControlLabel
                                    key={alt.id_alternativa}
                                    value={alt.text_alternativa}
                                    control={<Radio color="primary" />}
                                    label={alt.text_alternativa}
                                    />
                                )
                            })
                        }
                    </RadioGroup>
                </FormControl>
            </Grid>
        </>
    )
};

export default SelectRadio;