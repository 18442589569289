import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import 'moment/locale/pt-br';
import 'react-quill/dist/quill.snow.css';
import './views/PortifolioForm/styles.css';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
