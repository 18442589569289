import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Button,
  IconButton,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  CardHeader,
  CardActions,
  CircularProgress
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { PlayCircleFilledWhite, CancelPresentation } from '@material-ui/icons';
import Page from 'src/components/Page';
import AuthContext from 'src/contexts/AuthContext';
import getIdYoutube from '../../utils/getIdYoutube';
import api from '../../services/api';
import CartaDeAutorizacao from '../CartaDeAutorizacao';
import showSnack from '../../utils/snacks';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  container: {
    background: '#eee',
    padding: 10,
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '-1px -9px 40px #888',
    marginBottom: 20
  },
  btnSeminario: {
    marginTop: 5,
    width: '90%',
    border: '2px solid #b00f14',
    borderRadius: 8,
    padding: 10,
    color: '#b00f14',
    '&:hover': {
      color: '#fff',
      background: '#b00f14',
      transition: 'background-color 0.5s',
      border: '2px solid #e5b31a'
    }
  },
  btnAutorizacao: {
    border: '3px solid #b00f14',
    background: '#b00f14',
    borderRadius: 8,
    padding: 10,
    color: '#fff',
    margin: 4,
    '&:hover': {
      color: '#fff',
      background: '#820d10',
      transition: 'background-color 0.2s',
      border: '3px solid #e5b31a'
    }
  },
  media: {
    height: 200
  },
  background: {
    opacity: 0.8,
    background: 'no-repeat center',
    backgroundSize: 'cover',
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  imgDetalhesCurso: {
    borderRadius: 8,
    [theme.breakpoints.down('xs')]: {
      height: '8rem'
    },
    [theme.breakpoints.up('sm')]: {
      height: '16rem'
    },
    [theme.breakpoints.up('md')]: {
      height: '20rem'
    },
    [theme.breakpoints.up('lg')]: {
      height: '25rem'
    }
  }
}));

const DetalhesCurso = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { ra } = React.useContext(AuthContext);
  const course = sessionStorage.getItem('@c@cous@eea@');
  const [loading, setLoading] = useState(false);

  const [dados, setDados] = useState([]);
  const [videoSeminario, setVideoSeminario] = useState([{ id_video: '' }]);
  const [pdfData, setPdfData] = useState([]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (ra) {
      api
        .post('seminarios/course-ra', {
          title_curso: course,
          ra
        })
        .then(({ data }) => {
          setDados(data);
          setLoading(false);
        })
        .catch(e => {
          showSnack(
            'Não possível localizar os dados do curso',
            enqueueSnackbar,
            'error'
          );
        });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (ra) {
      api
        .post('aluno/data-pdf', {
          username: ra
        })
        .then(({ data }) => {
          setPdfData(data);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <Page className={classes.root} title="Detalhes Curso">
      {loading ? (
        <Container>
          <Grid container style={{ marginBottom: 20 }}>
            <Grid
              item
              xs={12}
              style={{
                borderRadius: 8,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 10
              }}
            >
              <CircularProgress />
              <Typography
                style={{ color: '#b00f14', fontWeight: 'bold', fontSize: 18 }}
              >
                Aguarde, por favor...
              </Typography>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container>
          <Grid container style={{ marginBottom: 20 }}>
            <Grid
              item
              xs={12}
              style={{
                backgroundImage: `url(${dados.link_image_curso})`,
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center'
              }}
              className={classes.imgDetalhesCurso}
            />
          </Grid>

          <Grid container className={classes.container}>
            <Grid item xs={12} style={{ padding: 10, textAlign: 'center' }}>
              <Typography
                style={{
                  textTransform: 'uppercase',
                  color: '#b00f14',
                  fontSize: '1.8rem',
                  fontFamily: 'Lato',
                  fontWeight: 'bold'
                }}
              >
                {dados.title_curso}
              </Typography>
            </Grid>

            {dados.title_curso && (
              <Grid item xs={12} style={{ padding: 20, textAlign: 'justify' }}>
                <Typography style={{ fontFamily: 'Roboto' }}>
                  {parse(dados.description_curso)}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid container className={classes.container}>
            <Grid
              item
              xs={12}
              style={{
                padding: 4,
                textAlign: 'left',
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '2px solid #b00f14'
              }}
            >
              <Typography
                style={{
                  textTransform: 'uppercase',
                  color: '#b00f14',
                  fontSize: '1rem',
                  fontFamily: 'Lato',
                  fontWeight: 'bold'
                }}
              >
                Documentos essenciais para a visita
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} style={{ paddingBottom: 8 }}>
              <Card
                style={{
                  margin: 8,
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column'
                }}
              >
                <CardHeader
                  title={
                    <Typography
                      style={{
                        color: '#b00f14',
                        fontSize: 20,
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        textAlign: 'center'
                      }}
                    >
                      Termo de Aceite
                    </Typography>
                  }
                />

                <CardContent>
                  <Typography style={{ textAlign: 'justify', padding: 10 }}>
                    Documento obrigatório que deverá ser impresso em 2 vias e
                    assinado pelo responsável pela sua acolhida em ambiente
                    profissional.
                  </Typography>
                </CardContent>

                <CardActions>
                  <CartaDeAutorizacao
                    nome={pdfData.aluno?.nome}
                    cpf={pdfData.aluno?.cpf}
                    matricula={pdfData.aluno?.ra}
                    curso={course}
                    codeValidation={pdfData?.codePDF}
                  >
                    <Button className={classes.btnAutorizacao} fullWidth>
                      Baixar Termo de Aceite
                    </Button>
                  </CartaDeAutorizacao>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} style={{ paddingBottom: 8 }}>
              <Card
                style={{
                  margin: 8,
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column'
                }}
              >
                <CardHeader
                  title={
                    <Typography
                      style={{
                        color: '#b00f14',
                        fontSize: 20,
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        textAlign: 'center'
                      }}
                    >
                      Termo de cessão de direitos de uso de imagem
                    </Typography>
                  }
                />

                <CardContent>
                  <Typography style={{ textAlign: 'justify', padding: 10 }}>
                    Ao realizar registros fotográficos durante a sua visita
                    técnica ou entrevista com o profissional, você deverá
                    apresentar o Termo de Cessão de Direito de Imagem, caso o
                    seu registro fotográfico tenha a sua imagem ou de outras
                    pessoas. Lembrando que esse registro fotográfico é
                    importante para a construção do seu portfólio!
                  </Typography>
                </CardContent>

                <CardActions>
                  <Button
                    className={classes.btnAutorizacao}
                    fullWidth
                    href="https://dirin.s3.amazonaws.com/ambiente_profissional/documents/termo_uso_imagem.pdf"
                    target="_blank"
                  >
                    Baixar Termo de uso de imagem
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>

          <Grid container className={classes.container}>
            <Grid
              item
              xs={12}
              style={{
                padding: 10,
                textAlign: 'left',
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '2px solid #b00f14'
              }}
            >
              <Typography
                style={{
                  padding: 10,
                  textTransform: 'uppercase',
                  color: '#b00f14',
                  fontSize: '1rem',
                  fontFamily: 'Lato',
                  fontWeight: 'bold'
                }}
              >
                Baixar Roteiro de ações para imersão em ambiente profissional
              </Typography>
            </Grid>

            {dados.seminarios?.map(seminary => {
              const idYoutube = getIdYoutube(seminary.link_video_seminario);

              return (
                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 5
                  }}
                  key={dados.id}
                >
                  <Card style={{ background: '#ced4da', width: '100%' }}>
                    <Typography
                      style={{
                        padding: 10,
                        fontFamily: 'Lato',
                        fontSize: '1.2rem',
                        color: '#b00f14'
                      }}
                    >
                      {seminary.titulo_seminario}
                    </Typography>
                    {seminary.link_video_seminario !== '-' && (
                      <CardContent
                        className={classes.background}
                        style={{
                          backgroundImage: `url("http://img.youtube.com/vi/${idYoutube}/0.jpg")`
                        }}
                        onClick={() => {
                          setOpen(true);
                          setVideoSeminario({ id_video: idYoutube });
                        }}
                      >
                        <PlayCircleFilledWhite
                          style={{ color: 'red', fontSize: '5rem' }}
                        />
                      </CardContent>
                    )}
                  </Card>

                  {seminary.link_roteiro_seminario !== '-' ? (
                    <Button
                      className={classes.btnSeminario}
                      href={seminary.link_roteiro_seminario}
                      target="_blank"
                    >
                      Baixar Roteiro
                    </Button>
                  ) : (
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        marginTop: 8,
                        color: '#444',

                        padding: 8
                      }}
                    >
                      Disponível em breve
                    </span>
                  )}
                </Grid>
              );
            })}

            <Grid
              item
              xs={12}
              style={{
                marginTop: 20,
                textAlign: 'center',
                borderTop: '1px dashed #bbb'
              }}
            >
              <Typography
                style={{
                  color: '#444',
                  fontSize: '0.7rem',
                  fontWeight: 'bold'
                }}
              >
                Copyright &copy; DIRIN - PROEAD - AIAMIS - 2020.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      )}

      <Dialog
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ background: '#0000009e' }}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setOpen(false)}
        >
          <IconButton
            style={{ color: '#b00f14' }}
            onClick={() => setOpen(false)}
          >
            <CancelPresentation />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <iframe
            style={{ width: '100%', height: '30rem' }}
            src={`https://www.youtube.com/embed/${videoSeminario.id_video}`}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen"
          ></iframe>
        </DialogContent>
      </Dialog>
    </Page>
  );
};

export default DetalhesCurso;
