import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Add, Close, Description } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ShowSnack from 'src/utils/snacks';
import api from '../../../services/api';

import SeletorArquivos from './SeletorArquivos';
import { Alert } from '@material-ui/lab';
import { Upload } from 'react-feather';

const useStyles = makeStyles(() => ({
  areaFieldset: {
    padding: 4,
    border: '1px solid #444',
    width: '100%',
    margin: '10px 0px',
    borderRadius: 4,
    background: '#dedede'
  },
  legendTitle: {
    padding: '0px 8px',
    textTransform: 'uppercase',
    fontFamily: 'roboto',
    fontWeight: 700,
    fontSize: 12,
    color: '#222'
  },
  dialogTitle: {
    textAlign: 'center',
    color: '#fff',
    fontSize: 20,
    fontWeight: 'bold'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  }
}));

function DialogRegistrosFotograficos({
  imagensCadastradas,
  idPortifolio,
  open,
  setOpen
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [recarregar, setRecarregar] = useState(false);
  const [openConfirmDel, setOpenConfirmDel] = useState(false);
  const [openSelecionarImagens, setOpenSelecionarImagens] = useState(false);
  const [loadingComponentRegistro, setLoadingComponentRegistro] = useState(
    false
  );

  const [imagens, setImagens] = useState([]);
  const [linksImagens, setLinksImagens] = useState([]);
  const [imageId, setImageId] = useState('');
  const [position, setPosition] = useState('');

  const handleCloseConfirmDel = () => {
    setOpenConfirmDel(false);
    setImageId('');
  };

  const delImagem = () => {
    api
      .delete(`registros-fotografico/${imageId}`)
      .then(() => {
        ShowSnack('A exclusão foi feita com sucesso!', enqueueSnackbar);
        handleCloseConfirmDel();
        imagensCadastradas.splice(position, 1);
        setRecarregar(!recarregar);
      })
      .catch(() => {
        ShowSnack('[Erro]: Erro ao excluir!', enqueueSnackbar, 'error');
      });
  };

  const salvarImagens = () => {
    setLoadingComponentRegistro(true);
    if (imagens.length > 0) {
      if (imagensCadastradas.length + imagens.length <= 4) {
        const formData = new FormData();
        formData.append('id_portifolio', idPortifolio);
        imagens.forEach(image => {
          formData.append('images[]', image);
        });

        api
          .post('registros-fotografico', formData)
          .then(({ data }) => {
            data.map(imgs => {
              imagensCadastradas.push(imgs);
            });
            setImagens([]);
            setLoadingComponentRegistro(false);
            setOpenSelecionarImagens(false);
            ShowSnack('Imagens cadastradas com sucesso', enqueueSnackbar);
          })
          .catch(e => {
            console.log(e);
          });
      } else {
        ShowSnack(
          'O limite de registros fotográficos é 4',
          enqueueSnackbar,
          'warning'
        );
      }
    } else {
      ShowSnack('Selecione novas imagens!', enqueueSnackbar, 'warning');
    }
  };

  useEffect(() => {
    (async function() {
      return await Promise.all(
        imagensCadastradas.map(async (imagem, index) => {
          const { data } = await api
            .get(`registros-fotografico/${imagem.id}`)
            .catch(e => {
              console.log(e);
            });

          if (!data) {
            return;
          }

          return (
            <Grid item xs={12} md={3} key={imagem.id} style={{ padding: 8 }}>
              <Card style={{ border: '1px solid #000' }}>
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                      }}
                    >
                      <IconButton
                        style={{
                          color: '#b00f14'
                        }}
                        onClick={() => {
                          setImageId(imagem.id);
                          setPosition(index);
                          setOpenConfirmDel(true);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <img
                      src={data}
                      alt="Imagem Cadastrada"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })
      );
    })().then(images => setLinksImagens(images));
  }, [imagensCadastradas.length, recarregar]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle style={{ background: '#b00f14' }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <Box style={{ flex: 1 }} />

            <Box style={{ flex: 1 }}>
              <Typography
                style={{
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: 20,
                  fontWeight: 'bold'
                }}
              >
                Registros Fotográficos
              </Typography>
              <Typography
                style={{
                  textAlign: 'center',
                  color: '#fff',
                  fontSize: 26,
                  fontWeight: 'bold'
                }}
              >
                {imagens.length + imagensCadastradas.length}/4
              </Typography>
            </Box>

            <Box
              style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}
            >
              {imagens.length + imagensCadastradas.length < 4 && (
                <Button
                  variant="text"
                  style={{ padding: 16, margin: 5, color: '#fff' }}
                  onClick={() => {
                    setOpenSelecionarImagens(true);
                  }}
                  startIcon={<Add />}
                >
                  Adicionar Foto
                </Button>
              )}
            </Box>
          </Box>
        </DialogTitle>

        {imagensCadastradas.length > 0 && (
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <fieldset className={classes.areaFieldset}>
                    <legend className={classes.legendTitle}>
                      Imagens Salvas
                    </legend>
                    <Grid container>
                      {linksImagens.map(img => {
                        return img;
                      })}
                    </Grid>
                  </fieldset>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>

      {/* Dialog Selecionar Registros Fotogŕaficos */}
      <Dialog
        open={openSelecionarImagens}
        onClose={() => {
          setOpenSelecionarImagens(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={{ background: '#b00f14' }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <div />

            <Typography
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 20,
                color: '#fff'
              }}
            >
              Selecionar Registros Fotográficos
            </Typography>

            {loadingComponentRegistro ? (
              <div />
            ) : (
              <Button
                variant="text"
                style={{ color: '#fff' }}
                onClick={() => {
                  salvarImagens();
                }}
              >
                <Upload />
                &nbsp;&nbsp;Enviar
              </Button>
            )}
          </Box>
        </DialogTitle>

        <DialogContent>
          {loadingComponentRegistro ? (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <SeletorArquivos
              state={imagens}
              setState={setImagens}
              imagensCadastradas={imagensCadastradas}
              setOpen={setOpenSelecionarImagens}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Confirmar Exclusão */}
      <Dialog
        open={openConfirmDel}
        onClose={() => {
          handleCloseConfirmDel();
        }}
      >
        <DialogTitle style={{ background: '#b00f14' }}>
          <Typography className={classes.dialogTitle}>
            Excluir Registro
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Alert severity="warning">
            <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>
              Tem certeza que deseja excluir este registro fotográfico? Após a
              confirmação o arquivo será excluído e não será possível recuperar.
            </Typography>
          </Alert>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={() => {
              delImagem();
            }}
          >
            Sim!
          </Button>

          <Button
            variant="contained"
            color="secondary"
            style={{ margin: 5 }}
            onClick={() => {
              handleCloseConfirmDel();
            }}
          >
            Não!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogRegistrosFotograficos;
