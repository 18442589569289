import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import { CloudUpload, Telegram, CheckCircleOutline } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import showSnack from '../../utils/snacks';
import AuthContext from 'src/contexts/AuthContext';
import api from '../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgLogin: {
    [theme.breakpoints.down('sm')]: {
      height: 100,
      width: 100
    },
    [theme.breakpoints.up('sm')]: {
      height: 150,
      width: 190
    }
  },
  container: {
    background: '#eee',
    padding: 10,
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '-1px -9px 40px #888',
    marginBottom: 20
  },
  gridCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px dashed #bbb'
  },
  btnSalvar: {
    margin: 5,
    backgroundColor: '#b00f14',
    color: '#fff',
    '&:hover': {
      background: '#820d10',
      color: '#fff',
      transition: 'background-color 0.5s'
    }
  },
  inputFile: {
    display: 'none'
  },
  labelFile: {
    padding: '10px 5px',
    width: '40%',
    backgroundColor: '#e5b31a',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    marginTop: '10px',
    cursor: 'pointer',
    margin: 10,
    borderRadius: 8,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#c19716',
      transition: 'background-color 0.2s'
    }
  },
  labelFileSelected: {
    padding: '10px 5px',
    width: '40%',
    backgroundColor: '#0cb96e',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    marginTop: '10px',
    cursor: 'pointer',
    margin: 10,
    borderRadius: 8,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#0f9058',
      transition: 'background-color 0.2s'
    }
  }
}));

const FormAcoes = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { ra } = useContext(AuthContext);
  const navigate = useNavigate();
  const idSeminario = useParams().id_seminario;
  const course = sessionStorage.getItem('@c@cous@eea@');

  const [openConfirm, setConfirm] = useState(false);
  const [progressState, setProgressState] = useState(0);

  const [carta, setCarta] = useState('');
  const [termo, setTermo] = useState('');

  const salvar = () => {
    const formData = new FormData();
    formData.append('path_carta', carta);
    formData.append('id', idSeminario);
    formData.append('ra', ra);

    api
      .post('set-carta', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: event => {
          let progress = Math.round((event.loaded * 100) / event.total);

          setProgressState(progress);
        }
      })
      .then(e => {
        if (e.data.type === 'extname') {
          showSnack(
            'Somente é aceito arquivo dos tipos jpg, jpeg, png, ou pdf',
            enqueueSnackbar,
            'warning'
          );

          navigate(`/app/form-acoes/${idSeminario}`);
          return;
        }
        if (e.data.type === 'size') {
          showSnack(
            'Você superou o tamanho maximo do arquivo',
            enqueueSnackbar,
            'warning'
          );

          navigate(`/app/form-acoes/${idSeminario}`);
          return;
        }
        navigate(`/app/form-acoes/${idSeminario}`);
        showSnack(
          'Envio da carta de autorização feita com sucesso!',
          enqueueSnackbar
        );
        // return;
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    api
      .post('respostas/getByRaAndIdSeminario', {
        ra,
        id_seminario: idSeminario
      })
      .then(({ data }) => {
        if (data.length > 0) {
          if (
            (data[0]?.seminario.curso.title_curso).toUpperCase() ===
            course.toUpperCase()
          ) {
            if (data[0]?.seminario.cartaAutoriza[0]?.path_carta_autorizacao) {
              if (data[0]?.id_formulario) {
                if (data[0]?.questoes.length > 0) {
                  navigate('/app/enviar-acoes');
                } else {
                  navigate(`/app/form-acoes/${idSeminario}`);
                }
              } else {
                navigate('/app/enviar-acoes');
              }
            }
          } else {
            navigate('/app/enviar-acoes');
          }
        } else {
          navigate('/app/enviar-acoes');
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  return (
    <Page
      className={classes.root}
      title="Roteiro para a visita técnica de imersão no ambiente profissional"
    >
      <Container>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.gridCenter}>
            <img
              alt="Logo AIAMIS"
              className={classes.imgLogin}
              src="/favicon.png"
            />

            <Typography
              style={{
                color: '#444',
                fontSize: '2rem',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              Envio de carta de autorização e termo de cessão de direitos de uso
              de imagem
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.gridCenter}>
            <label
              htmlFor="carta"
              className={carta ? classes.labelFileSelected : classes.labelFile}
            >
              {carta ? (
                <>
                  <CheckCircleOutline
                    style={{ fontSize: '3rem', color: '#fff' }}
                  />
                  <br />
                  Arquivo selecionado
                </>
              ) : (
                <>
                  <CloudUpload style={{ fontSize: '3rem', color: '#fff' }} />
                  <br />
                  Selecione o termo de aceite assinado
                </>
              )}
            </label>
            <input
              id="carta"
              type="file"
              accept="image/png, image/jpg, image/jpeg, application/pdf"
              className={classes.inputFile}
              onChange={e => {
                if (e.target.files[0].size <= 5242880) {
                  setCarta(e.target.files[0]);
                } else {
                  showSnack(
                    'O tamanho do arquivo suportado é 5MB',
                    enqueueSnackbar,
                    'warning'
                  );
                }
              }}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridCenter}>
            <label
              htmlFor="termo"
              className={termo ? classes.labelFileSelected : classes.labelFile}
            >
              {termo ? (
                <>
                  <CheckCircleOutline
                    style={{ fontSize: '3rem', color: '#fff' }}
                  />
                  <br />
                  Arquivo selecionado
                </>
              ) : (
                <>
                  <CloudUpload style={{ fontSize: '3rem', color: '#fff' }} />
                  <br />
                  Selecione o termo
                </>
              )}
            </label>
            <input
              id="termo"
              type="file"
              accept="image/png, image/jpg, image/jpeg, application/pdf"
              className={classes.inputFile}
              onChange={e => {
                if (e.target.files[0].size <= 5242880) {
                  setTermo(e.target.files[0]);
                } else {
                  showSnack(
                    'O tamanho do arquivo suportado é 5MB',
                    enqueueSnackbar,
                    'warning'
                  );
                }
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{ textAlign: 'center', borderTop: '1px dashed #bbb' }}
          >
            <Button
              className={classes.btnSalvar}
              onClick={() => {
                setConfirm(!!carta);
              }}
            >
              <Telegram />
              &nbsp;&nbsp; Enviar
            </Button>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 20, textAlign: 'center' }}>
            <Typography
              style={{ color: '#444', fontSize: '0.7rem', fontWeight: 'bold' }}
            >
              Copyright &copy; DIRIN - PROEAD - AIAMIS - 2020.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={openConfirm}
        onClose={() => setConfirm(progressState > 0)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: 'center' }}
          >
            {progressState > 0 ? (
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  color: '#b00f14'
                }}
              >
                Por favor aguarde!
              </span>
            ) : (
              <span style={{ color: '#b00f14', fontWeight: 'bold' }}>
                Confirma o envio desta carta de autorização ?
              </span>
            )}
          </DialogContentText>
          {progressState > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <LinearProgress
                variant="determinate"
                value={progressState}
                style={{ width: '100%' }}
              />
              <span
                style={{
                  marginLeft: 8,
                  fontFamily: 'Roboto',
                  fontWeight: 'bold',
                  color: '#b00f14'
                }}
              >{`${progressState}%`}</span>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => salvar()}
            className={classes.btnSalvar}
            disabled={progressState > 0}
          >
            Sim!
          </Button>
          <Button
            variant="outlined"
            onClick={() => setConfirm(false)}
            color="primary"
            disabled={progressState > 0}
          >
            Não!
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default FormAcoes;
