import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ReactQuill from 'react-quill';
import parse from 'html-react-parser';

import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Home from '@material-ui/icons/Home';
import Check from '@material-ui/icons/Check';

import { useSnackbar } from 'notistack';
import showSnack from 'src/utils/snacks';

import api from 'src/services/api';

import DialogConfirmEnvio from '../DialogConfirmEnvio';
import apiIbge from 'src/services/apiIbge';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%',
    background: '#fff'
  },
  formLabel: {
    fontWeight: 'bold',
    color: '#b00f14'
  },
  areaFieldset: {
    padding: 4,
    border: '1px solid #444',
    width: '100%',
    margin: '10px 0px',
    borderRadius: 4,
    background: '#dedede'
  },
  legendTitle: {
    padding: '0px 8px',
    textTransform: 'uppercase',
    fontFamily: 'roboto',
    fontWeight: 700,
    fontSize: 12,
    color: '#222'
  },
  editorText: {
    background: '#fff',
    borderRadius: 8,
    border: '1px solid #ccc',
    height: '200px'
  },
  btnSalvar: {
    margin: 5,
    backgroundColor: '#b00f14',
    color: '#fff',
    '&:hover': {
      background: '#820d10',
      transition: 'background-color 0.5s'
    }
  },
  btnVoltar: {
    margin: 5,
    backgroundColor: '#e5b31a',
    color: '#444',
    '&:hover': {
      backgroundColor: '#c19716',
      transition: 'background-color 0.2s'
    }
  },
}));

export default function EnvioFormulario({
  isSeminarThree = false, ra, idSeminario, setIdPortifolio, setActiveStep
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const estados = [
    { sigla: 'FL', nome: 'Flórida - EUA' },
    { sigla: 'AC', nome: 'Acre' },
    { sigla: 'AL', nome: 'Alagoas' },
    { sigla: 'AM', nome: 'Amazonas' },
    { sigla: 'AP', nome: 'Amapá' },
    { sigla: 'BA', nome: 'Bahia' },
    { sigla: 'CE', nome: 'Ceará' },
    { sigla: 'DF', nome: 'Distrito Federal' },
    { sigla: 'ES', nome: 'Espírito Santo' },
    { sigla: 'GO', nome: 'Goiás' },
    { sigla: 'MA', nome: 'Maranhão' },
    { sigla: 'MG', nome: 'Minas Gerais' },
    { sigla: 'MS', nome: 'Mato Grosso do Sul' },
    { sigla: 'MT', nome: 'Mato Grosso' },
    { sigla: 'PA', nome: 'Pará' },
    { sigla: 'PB', nome: 'Paraíba' },
    { sigla: 'PE', nome: 'Pernambuco' },
    { sigla: 'PI', nome: 'Piauí' },
    { sigla: 'PR', nome: 'Paraná' },
    { sigla: 'RJ', nome: 'Rio de Janeiro' },
    { sigla: 'RN', nome: 'Rio Grande do Norte' },
    { sigla: 'RO', nome: 'Rondônia' },
    { sigla: 'RR', nome: 'Roraima' },
    { sigla: 'RS', nome: 'Rio Grande do Sul' },
    { sigla: 'SE', nome: 'Sergipe' },
    { sigla: 'SC', nome: 'Santa Catarina' },
    { sigla: 'SP', nome: 'São Paulo' },
    { sigla: 'TO', nome: 'Tocantins' }
  ];

  const [loadingEnvioFormulario, setLoadingEnvioFormulario] = useState(false);

  const [cidades, setCidades] = useState([]);
  const [estado, setEstado] = useState('');

  const [textLimit, setTextLimit] = useState('');
  const [openLimit, setOpenLimit] = useState(false);
  const [openConfirm, setConfirm] = useState(false);

  const [dataForm, setDataForm] = useState({
    nomeEmpresa: '',
    enderecoEmpresa: '',
    estadoEmpresa: '',
    cidadeEmpresa: '',
    dataVisita: '',
    horaInicioVisita: '',
    horaFinalVisita: '',
    apresentacao: '',
    atividadesRealizadas: '',
    setoresVisitados: '',
    desenvolvimento: '',
    conclusao: ''
  });

  const handleDataForm = (position, value) => {
    setDataForm({ ...dataForm, [position]: value });
  };

  const [apresentacaoCountLength, setApresentacaoCountLength] = useState(0);
  const [
    atividadesRealizadasCountLength,
    setAtividadesRealizadasCountLength
  ] = useState(0);
  const [
    setoresVisitadosCountLength,
    setSetoresVisitadosCountLength
  ] = useState(0);
  const [desenvolvimentoCountLength, setDesenvolvimentoCountLength] = useState(
    0
  );
  const [conclusaoCountLength, setConclusaoCountLength] = useState(0);

  const salvar = () => {
    setLoadingEnvioFormulario(true);

    const formData = new FormData();
    formData.append('ra', ra);
    formData.append('id_seminario', idSeminario);
    formData.append('nome_empresa_visitada', dataForm.nomeEmpresa);
    formData.append('endereco_empresa_visitada', dataForm.enderecoEmpresa);
    formData.append('cidade_empresa_visitada', dataForm.cidadeEmpresa);
    formData.append('estado_empresa_visitada', dataForm.estadoEmpresa);
    formData.append('conclusao', dataForm.conclusao);

    if (!isSeminarThree) {
      formData.append('data_visita', dataForm.dataVisita);
      formData.append('horario_chegada_empresa_visitada', dataForm.horaInicioVisita);
      formData.append('horario_termino_visita', dataForm.horaFinalVisita);
      formData.append('apresentacao', dataForm.apresentacao);
      formData.append('setores_visitados', dataForm.setoresVisitados);
      formData.append('atividades_realizadas', dataForm.atividadesRealizadas);
      formData.append('desenvolvimento', dataForm.desenvolvimento);
    }

    if (
      ra
      && idSeminario
      && dataForm.nomeEmpresa
      && dataForm.enderecoEmpresa
      && dataForm.cidadeEmpresa
      && dataForm.estadoEmpresa
      && dataForm.conclusao
    ) {
      let process = true;

      if (!isSeminarThree) {
        if (
          dataForm.dataVisita
          && dataForm.apresentacao
          && dataForm.horaInicioVisita
          && dataForm.setoresVisitados
          && dataForm.atividadesRealizadas
          && dataForm.horaFinalVisita
          && dataForm.desenvolvimento
        ) {
          process = true;
        } else {
          process = false;
          showSnack('Preencha todos campos!', enqueueSnackbar, 'warning');
          setLoadingEnvioFormulario(false);
        }
      }

      if (process) {
        api
          .post('portifolio', formData)
          .then(({ data }) => {
            if (data.success === 'Portifólio criado') {
              showSnack('Portifolio enviado com sucesso!', enqueueSnackbar);
            }
            setIdPortifolio(data?.id);
            setActiveStep(1);
            setLoadingEnvioFormulario(false);
          })
          .catch((e) => {
            setLoadingEnvioFormulario(false);
            showSnack(
              'Oops! Ocorreu um erro ao realizar o envio!',
              enqueueSnackbar,
              'error'
            );
          });
      }
    } else {
      showSnack('Preencha todos campos!', enqueueSnackbar, 'warning');
      setLoadingEnvioFormulario(false);
    }
  };

  const validateForm = (e) => {
    e.preventDefault();

    const getInputs = Object.keys(dataForm);
    let verifyItem = 0;

    const arrayInputsSemiThree = [
      "dataVisita",
      "horaInicioVisita",
      "horaFinalVisita",
      "apresentacao",
      "atividadesRealizadas",
      "setoresVisitados",
      "desenvolvimento",
    ]

    getInputs.map((input) => {
      if (!verifyItem) {
        if (!dataForm[input]) {
          if (isSeminarThree && arrayInputsSemiThree.includes(input)) {
            return;
          } else {
            showSnack(`O campo de ${input} é obrigatório`, enqueueSnackbar, 'warning');
            verifyItem += 1;
          }
        }
      }
    });

    if (!verifyItem) {
      setConfirm(true);
    }
  };

  return (
    <Grid item xs={12}>
      <form onSubmit={validateForm}>
        <fieldset className={classes.areaFieldset}>
          <legend className={classes.legendTitle}>
            Empresa/Instituição Visitada
          </legend>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} style={{ padding: 10 }}>
                <FormLabel className={classes.formLabel}>
                  Nome Empresa:
                </FormLabel>
                <TextField
                  required
                  className={classes.textField}
                  id="nome_empresa_visitada"
                  placeholder="Digite o nome da Empresa/Instituição visitada "
                  variant="outlined"
                  value={dataForm.nomeEmpresa}
                  onChange={(e) => handleDataForm('nomeEmpresa', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={6} style={{ padding: 10 }}>
                <FormLabel className={classes.formLabel}>
                  Endereço:
                </FormLabel>
                <TextField
                  required
                  className={classes.textField}
                  id="endereco_empresa_visitada"
                  placeholder="Digite o endereço da instituição"
                  variant="outlined"
                  type="text"
                  value={dataForm.enderecoEmpresa}
                  onChange={(e) => handleDataForm('enderecoEmpresa', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} md={2} style={{ padding: 10 }}>
                <FormLabel className={classes.formLabel}>
                  Estado:
                </FormLabel>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                >
                  <Select
                    required
                    id="estado_empresa_visitada"
                    onChange={(e) => {
                      setEstado(e.target.value);
                      // setFormData({ ...formData, cidade_empresa_visitada: '' });
                      if (e.target.value === 'FL') {
                        handleDataForm('estadoEmpresa', 'Flórida - EUA');
                        setCidades([{ nome: 'Polo Orlando' }]);
                      } else {
                        apiIbge.get(`${e.target.value}/municipios`)
                          .then(({ data }) => {
                            handleDataForm(
                              'estadoEmpresa',
                              data[0].microrregiao.mesorregiao.UF.nome
                            );
                            setCidades(data);
                          })
                          .catch((e) => console.log(e));
                      }
                    }}
                    value={estado}
                  >
                    <MenuItem value="" disabled>
                      <em>Selecione...</em>
                    </MenuItem>
                    {estados.map((estados, index) => {
                      return (
                        <MenuItem key={index} value={estados.sigla}>
                          {estados.nome}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} style={{ padding: 10 }}>
                <FormLabel className={classes.formLabel}>
                  Cidade:
                </FormLabel>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                >
                  <Select
                    required
                    id="cidade_empresa_visitada"
                    value={dataForm.cidadeEmpresa}
                    onChange={(e) => handleDataForm('cidadeEmpresa', e.target.value)}
                  >
                    <MenuItem value="" disabled>
                      <em>Selecione...</em>
                    </MenuItem>
                    {cidades.map((cidade, index) => {
                      return (
                        <MenuItem key={index} value={cidade.nome}>
                          {cidade.nome}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </fieldset>

        {!isSeminarThree && (
          <fieldset className={classes.areaFieldset}>
            <legend className={classes.legendTitle}>
              Data/Hora Visita
            </legend>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={4} style={{ padding: 10 }}>
                  <FormLabel className={classes.formLabel}>
                    Data:
                  </FormLabel>
                  <TextField
                    required
                    id="data_visita"
                    type="date"
                    variant="outlined"
                    className={classes.textField}
                    value={dataForm.dataVisita}
                    onChange={(e) => handleDataForm('dataVisita', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={4} style={{ padding: 10 }}>
                  <FormLabel className={classes.formLabel}>
                    Horário de chegada:
                  </FormLabel>
                  <TextField
                    required
                    id="horario_chegada_empresa_visitada"
                    type="time"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      step: 300 // 5 min
                    }}
                    variant="outlined"
                    value={dataForm.horaInicioVisita}
                    onChange={(e) => handleDataForm('horaInicioVisita', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={4} style={{ padding: 10 }}>
                  <FormLabel className={classes.formLabel}>
                    Horário de término da visita:
                  </FormLabel>
                  <TextField
                    required
                    id="horario_termino_visita"
                    type="time"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      step: 300 // 5 min
                    }}
                    variant="outlined"
                    value={dataForm.horaFinalVisita}
                    onChange={(e) => handleDataForm('horaFinalVisita', e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </fieldset>
        )}

        <fieldset className={classes.areaFieldset}>
          <legend className={classes.legendTitle}>
            Relatório Visita
          </legend>

          <Grid item xs={12}>
            <Grid container>
              {!isSeminarThree && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{ padding: 10, marginBottom: 10 }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormLabel className={classes.formLabel}>
                      Apresentação:
                    </FormLabel>
                    <Typography
                      style={{
                        textAlign: 'right',
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: '#444'
                      }}
                    >
                      {apresentacaoCountLength > 0
                        ? apresentacaoCountLength - 1
                        : apresentacaoCountLength}
                      {' '}
                      de 2500 caracteres
                    </Typography>
                  </Grid>

                  <ReactQuill
                    inputProps={{ required: 'true' }}
                    className={classes.editorText}
                    theme={false}
                    value={dataForm.apresentacao}
                    placeholder="Faça um breve relato sobre as empresa/ instituição, o seu histórico, a sua missão e princípios (se houver), onde se localiza, o ramo de atividade, mercado a onde atua, e outras informações que puder coletar."
                    onChange={(content, delta, source, editor) => {
                      if (editor.getLength() - 1 > 2500) {
                        setTextLimit(content);
                        setOpenLimit(true);
                      } else {
                        handleDataForm('apresentacao', content);
                      }
                      setApresentacaoCountLength(editor.getLength());
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ padding: 10, marginBottom: 10 }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormLabel className={classes.formLabel}>
                      Atividades Realizadas:
                    </FormLabel>
                    <Typography
                      style={{
                        textAlign: 'right',
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: '#444'
                      }}
                    >
                      {atividadesRealizadasCountLength
                        ? atividadesRealizadasCountLength - 1
                        : atividadesRealizadasCountLength}
                      {' '}
                      de 2500 caracteres
                    </Typography>
                  </Grid>

                  <ReactQuill
                    required
                    className={classes.editorText}
                    theme={false}
                    value={dataForm.atividadesRealizadas}
                    placeholder="Digite o nome das atividades"
                    onChange={(content, delta, source, editor) => {
                      if (editor.getLength() - 1 > 2500) {
                        setTextLimit(content);
                        setOpenLimit(true);
                      } else {
                        handleDataForm('atividadesRealizadas', content);
                      }
                      setAtividadesRealizadasCountLength(
                        editor.getLength()
                      );
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ padding: 10, marginBottom: 10 }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormLabel className={classes.formLabel}>
                      Setores Visitados:
                    </FormLabel>
                    <Typography
                      style={{
                        textAlign: 'right',
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: '#444'
                      }}
                    >
                      {setoresVisitadosCountLength
                        ? setoresVisitadosCountLength - 1
                        : setoresVisitadosCountLength}
                      {' '}
                      de 2500 caracteres
                    </Typography>
                  </Grid>

                  <ReactQuill
                    required
                    className={classes.editorText}
                    theme={false}
                    value={dataForm.setoresVisitados}
                    placeholder="Digite o nome do setores visitados"
                    onChange={(content, delta, source, editor) => {
                      if (editor.getLength() - 1 > 2500) {
                        setTextLimit(content);
                        setOpenLimit(true);
                      } else {
                        handleDataForm('setoresVisitados', content);
                      }
                      setSetoresVisitadosCountLength(
                        editor.getLength()
                      );
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ padding: 10, marginBottom: 10 }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FormLabel className={classes.formLabel}>
                      Desenvolvimento:
                    </FormLabel>
                    <Typography
                      style={{
                        textAlign: 'right',
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: '#444'
                      }}
                    >
                      {desenvolvimentoCountLength
                        ? desenvolvimentoCountLength - 1
                        : desenvolvimentoCountLength}
                      {' '}
                      de 2500 caracteres
                    </Typography>
                  </Grid>

                  <ReactQuill
                    className={classes.editorText}
                    theme={false}
                    value={dataForm.desenvolvimento}
                    placeholder="Faça um relato sobre como foi a sua visita e descreva suas observações sobre o ambiente profissional visitado externo e internamente, setores, profissionais, atividades realizadas e se existem algum projeto ou programa de melhoria interna das atividades e serviços prestados"
                    onChange={(content, delta, source, editor) => {
                      if (editor.getLength() - 1 > 2500) {
                        setTextLimit(content);
                        setOpenLimit(true);
                      } else {
                        handleDataForm('desenvolvimento', content);
                      }
                      setDesenvolvimentoCountLength(editor.getLength());
                    }}
                  />
                </Grid>
              </>
              )}

              <Grid
                item
                xs={12}
                style={{ padding: 10, marginBottom: 10 }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <FormLabel className={classes.formLabel}>
                    Conclusão:
                  </FormLabel>
                  <Typography
                    style={{
                      textAlign: 'right',
                      fontSize: 12,
                      fontWeight: 'bold',
                      color: '#444'
                    }}
                  >
                    {conclusaoCountLength
                      ? conclusaoCountLength - 1
                      : conclusaoCountLength}
                    {' '}
                    de 2500 caracteres
                  </Typography>
                </Grid>

                <ReactQuill
                  className={classes.editorText}
                  theme={false}
                  value={dataForm.conclusao}
                  placeholder="Descreva a relevância da visita e porque, aspectos que lhe surpreenderam, poderá descrever aspectos negativos ou positivos. Realize outros comentários que julgar necessário"
                  onChange={(content, delta, source, editor) => {
                    if (editor.getLength() - 1 > 2500) {
                      setTextLimit(content);
                      setOpenLimit(true);
                    } else {
                      handleDataForm('conclusao', content);
                    }
                    setConclusaoCountLength(editor.getLength());
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </fieldset>

        <Grid
          item
          xs={12}
          style={{ textAlign: 'center', borderTop: '1px dashed #bbb' }}
        >
          <Button
            className={classes.btnVoltar}
            onClick={() => navigate('/app')}
            startIcon={<Home />}
          >
            Início
          </Button>
          <Button
            className={classes.btnSalvar}
            type="submit"
            startIcon={<Check />}
          >
            Salvar
          </Button>
        </Grid>
      </form>

      {/* Confirmar Envio */}
      <DialogConfirmEnvio
        open={openConfirm}
        loading={loadingEnvioFormulario}
        setOpen={setConfirm}
        actionButtonConfirm={salvar}
      />

      {/* Info sobre o número de caracteres alcançados */}
      <Dialog
        open={openLimit}
        onClose={() => setOpenLimit(false)}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="paper"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{ background: '#b00f14', borderBottom: '5px solid #e5b31a' }}
        >
          <Typography
            style={{
              textAlign: 'center',
              color: '#fff',
              fontSize: '1.2rem',
              fontWeight: 'bold'
            }}
          >
            O limite de caracteres foi alcançado!
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{ fontFamily: 'Roboto' }}>
          <Typography
            style={{ textAlign: 'center', color: '#777', fontWeight: 'bold' }}
          >
            O texto que será enviado:
          </Typography>
          {textLimit ? parse(textLimit) : ''}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenLimit(false)}
            className={classes.btnSalvar}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
