import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Add, Close, Description } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from '../../../services/api';

import SeletorTermos from './SeletorTermos';
import { Upload } from 'react-feather';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  areaFieldset: {
    padding: 4,
    border: '1px solid #444',
    width: '100%',
    margin: '10px 0px',
    borderRadius: 4,
    background: '#fafafa'
  },
  legendTitle: {
    padding: '0px 8px',
    textTransform: 'uppercase',
    fontFamily: 'roboto',
    fontWeight: 700,
    fontSize: 12,
    color: '#222'
  },
  btnTermos: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    width: '100%',
    background: '#b00f14',
    color: '#fff',
    border: 'none',
    borderRadius: 4,
    padding: 10,
    cursor: 'pointer',
    '&:hover': {
      background: '#86090d',
      transition: 'background-color 0.4s'
    }
  }
}));

function DialogTermosUsoImage({
  termosCadastradas,
  idPortifolio,
  open,
  setOpen
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [termos, setTermos] = useState([]);

  const [recarregar, setRecarregar] = useState(false);
  const [loadingComponentTermo, setLoadingComponentTermo] = useState(false);
  const [openSelecionarTermos, setOpenSelecionarTermos] = useState(false);
  const [openConfirmDel, setOpenConfirmDel] = useState(false);
  const [termoId, setTermoId] = useState('');
  const [position, setPosition] = useState('');

  const [linksTermos, setLinksTermos] = useState([]);

  const handleCloseConfirmDel = () => {
    setOpenConfirmDel(false);
    setTermoId('');
  };

  const delTermo = () => {
    api
      .delete(`termo-imagem/${termoId}`)
      .then(() => {
        ShowSnack('A exclusão foi feita com sucesso!', enqueueSnackbar);
        handleCloseConfirmDel();
        termosCadastradas.splice(position, 1);
        setRecarregar(!recarregar);
      })
      .catch(() => {
        ShowSnack('[Erro]: Erro ao excluir!', enqueueSnackbar, 'error');
      });
  };

  const salvarTermos = () => {
    setLoadingComponentTermo(true);

    const formData = new FormData();
    formData.append('id_portifolio', idPortifolio);
    termos.forEach(term => {
      formData.append('termo_uso_imagem[]', term);
    });

    api
      .post('termo-imagem', formData)
      .then(({ data }) => {
        data.map(term => {
          termosCadastradas.push(term);
        });
        setOpenSelecionarTermos(false);
        setTermos([]);
        setLoadingComponentTermo(false);
        ShowSnack('Termos cadastrados com sucesso', enqueueSnackbar);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    (async function() {
      return await Promise.all(
        termosCadastradas.map(async (termo, index) => {
          const { data } = await api
            .get(`termo-imagem/${termo.id}`)
            .catch(e => {
              console.log(e);
            });

          if (!data) {
            return;
          }

          return (
            <Grid item xs={12} md={3} key={termo.id} style={{ padding: 8 }}>
              <Card style={{ border: '1px solid #888' }}>
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                      }}
                    >
                      <IconButton
                        style={{
                          color: '#b00f14'
                        }}
                        onClick={() => {
                          setTermoId(termo.id);
                          setOpenConfirmDel(true);
                          setPosition(index);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Grid>

                    <Grid item xs={12}>
                      <a
                        className={classes.btnTermos}
                        href={data}
                        target="_blank"
                        key={index}
                      >
                        <Description style={{ fontSize: '5rem' }} />
                        <Typography
                          style={{ fontWeight: 'bold', fontSize: 18 }}
                        >
                          {`Termo ${
                            index >= 0 && index < 10 ? `0${index + 1}` : index
                          }`}
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })
      );
    })().then(terms => setLinksTermos(terms));
  }, [termosCadastradas.length, recarregar]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle style={{ background: '#b00f14' }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <Box style={{ flex: 1 }} />

            <Typography
              style={{
                color: '#fff',
                textAlign: 'center',
                fontSize: 20,
                fontWeight: 'bold'
              }}
            >
              Termos de Uso de Imagem
            </Typography>

            <Box
              style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                variant="text"
                style={{ padding: 16, margin: 5, color: '#fff' }}
                onClick={() => {
                  setOpenSelecionarTermos(true);
                }}
                startIcon={<Add />}
              >
                Adicionar Termo
              </Button>
            </Box>
          </Box>
        </DialogTitle>

        {linksTermos.length > 0 && (
          <DialogContent>
            <Grid container>
              {linksTermos.map(term => {
                return term;
              })}
            </Grid>
          </DialogContent>
        )}
      </Dialog>

      {/* Confirmar Exclusão */}
      <Dialog
        open={openConfirmDel}
        onClose={() => {
          handleCloseConfirmDel();
        }}
      >
        <DialogTitle style={{ background: '#b00f14' }}>
          <Typography className={classes.dialogTitle}>Excluir Termo</Typography>
        </DialogTitle>

        <DialogContent>
          <Alert severity="warning">
            <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>
              Tem certeza que deseja excluir este termo? Após a confirmação o
              arquivo será excluído e não será possível recuperar.
            </Typography>
          </Alert>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={() => {
              delTermo();
            }}
          >
            Sim!
          </Button>

          <Button
            variant="contained"
            color="secondary"
            style={{ margin: 5 }}
            onClick={() => {
              handleCloseConfirmDel();
            }}
          >
            Não!
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Selecionar Termos */}
      <Dialog
        open={openSelecionarTermos}
        onClose={() => {
          setOpenSelecionarTermos(false);
          setTermos([]);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={{ background: '#b00f14' }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <div />

            <Typography
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 20,
                color: '#fff'
              }}
            >
              Selecionar Termos de cessão de direito de uso de imagens
            </Typography>

            <Button
              variant="text"
              style={{ color: '#fff' }}
              onClick={() => {
                salvarTermos();
              }}
            >
              <Upload />
              &nbsp;&nbsp;Enviar
            </Button>
          </Box>
        </DialogTitle>

        <DialogContent>
          <SeletorTermos
            state={termos}
            setState={setTermos}
            setOpen={setOpenSelecionarTermos}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DialogTermosUsoImage;
