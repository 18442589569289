import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ArrowForward from '@material-ui/icons/ArrowForward';

import SeletorArquivos from './SeletorArquivos';
import DialogConfirmEnvio from '../DialogConfirmEnvio';
import ItemsFiles from './ItemsFiles';

import ShowSnack from 'src/utils/snacks';
import { formatBytes } from 'src/utils/formatarBytes';

import { useSnackbar } from 'notistack';
import api from 'src/services/api';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  appBar: {
    position: 'relative'
  },
  imgLogin: {
    [theme.breakpoints.down('sm')]: {
      height: 100,
      width: 100
    },
    [theme.breakpoints.up('sm')]: {
      height: 150,
      width: 190
    }
  },
  container: {
    background: '#eee',
    padding: 10,
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '-1px -9px 40px #888',
    marginBottom: 20
  },
  gridCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px dashed #bbb'
  },
  gridQuestion: {
    padding: 15
  },
  question: {
    color: '#444',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  answer: {
    paddingLeft: 50
  },
  qtAberta: {
    '&:focus': {
      outlineColor: '#b00f14'
    },
    width: '95%',
    border: '1px solid #ddd',
    borderRadius: 8,
    background: '#ddd',
    padding: 10,
    fontSize: 16,
    resize: 'none',
    fontFamily: 'Roboto'
  },
  btnSalvar: {
    margin: 5,
    backgroundColor: '#b00f14',
    color: '#fff',
    '&:hover': {
      background: '#820d10',
      transition: 'background-color 0.5s'
    }
  },
  btnVoltar: {
    margin: 5,
    backgroundColor: '#e5b31a',
    color: '#444',
    '&:hover': {
      backgroundColor: '#c19716',
      transition: 'background-color 0.2s'
    }
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%',
    background: '#fff'
  },
  formLabel: {
    fontWeight: 'bold',
    color: '#b00f14'
  },
  teste: {
    background: 'red'
  },
  inputFile: {
    display: 'none'
  },
  labelFile: {
    width: '100%',
    padding: '10px 5px',
    backgroundColor: '#e5b31a',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    marginTop: '10px',
    cursor: 'pointer',
    margin: 10,
    borderRadius: 8,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#c19716',
      transition: 'background-color 0.2s'
    }
  },
  labelFileDisabled: {
    width: '100%',
    padding: '10px 5px',
    backgroundColor: '#756a6a',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    marginTop: '10px',
    cursor: 'pointer',
    margin: 10,
    borderRadius: 8,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#544d4d',
      transition: 'background-color 0.2s'
    }
  },
  btnExcluir: {
    background: 'transparent',
    '&:hover': {
      background: 'transparent'
    },
    '&:click': {
      background: 'transparent'
    }
  },
  editorText: {
    background: '#fff',
    borderRadius: 8,
    border: '1px solid #ccc',
    height: '200px'
  },
  areaFieldset: {
    padding: 4,
    border: '1px solid #444',
    width: '100%',
    margin: '10px 0px',
    borderRadius: 4,
    background: '#fff'
  },
  legendTitle: {
    padding: '0px 8px',
    textTransform: 'uppercase',
    fontFamily: 'roboto',
    fontWeight: 700,
    fontSize: 12,
    color: '#222'
  }
}));

export default function EnvioRegistrosFotograficos({ idPortifolio, setActiveStep }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openConfirm, setOpenConfirm] = useState(false);

  const [imagensSelecionadas, setImagensSelecionadas] = useState([
    // {
    //   file: 'teste',
    //   id: moment(new Date).format('YYYYMMDDHHmmss'),
    //   name: 'teste',
    //   preview: '/caneta.png',
    //   progress: 0,
    //   uploaded: false,
    //   error: true,
    //   size: formatBytes(464681),
    // },
    // {
    //   file: 'teste',
    //   id: moment(new Date).format('YYYYMMDDHHmmss'),
    //   name: 'teste',
    //   preview: '/caneta.png',
    //   progress: 0,
    //   uploaded: false,
    //   error: false,
    //   size: formatBytes(464681),
    // },
    // {
    //   file: 'teste',
    //   id: moment(new Date).format('YYYYMMDDHHmmss'),
    //   name: 'teste',
    //   preview: '/caneta.png',
    //   progress: 0,
    //   uploaded: false,
    //   error: true,
    //   size: formatBytes(464681),
    // },
    // {
    //   file: 'teste',
    //   id: moment(new Date).format('YYYYMMDDHHmmss'),
    //   name: 'teste',
    //   preview: '/caneta.png',
    //   progress: 0,
    //   uploaded: false,
    //   error: false,
    //   size: formatBytes(464681),
    // },
    // {
    //   file: 'teste',
    //   id: moment(new Date).format('YYYYMMDDHHmmss'),
    //   name: 'teste',
    //   preview: '/caneta.png',
    //   progress: 0,
    //   uploaded: false,
    //   error: false,
    //   size: formatBytes(464681),
    // },
    // {
    //   file: 'teste',
    //   id: moment(new Date).format('YYYYMMDDHHmmss'),
    //   name: 'teste',
    //   preview: '/caneta.png',
    //   progress: 0,
    //   uploaded: false,
    //   error: false,
    //   size: formatBytes(464681),
    // },
  ]);

  const getLengthImagesSelected = () => {
    const arrayLength = [];
    
    imagensSelecionadas.map((img) => {
      if (!img.error) {
        arrayLength.push(img);
      }
    });

    return arrayLength.length;
  }

  const updateFile = (id, data) => {
    setImagensSelecionadas((state) =>
      state.map((file) => (file.id === id ? { ...file, ...data } : file))
    );
  };

  const processUpload = (file) => {
    const formData = new FormData();
    formData.append('images', file.file);
    formData.append('id_portifolio', idPortifolio);

    api
      .post('registros-fotografico', formData, {
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
  
          updateFile(file.id, { progress })
        }
      })
      .then(({ data }) => {
        updateFile(file.id, {
          uploaded: true,
          id: data[0].id
        });
      })
      .catch(e => {
        updateFile(file.id, {
          error: true,
        });
      });
  }

  const handleUpload = (files) => {
    if (getLengthImagesSelected() + files.length > 4) {
      ShowSnack('O limite de imagens é 4.', enqueueSnackbar, 'warning');
      return;
    }

    let uploadedFiles = [];
    
    files.map((file, index) => {
      const verifyExists = imagensSelecionadas.filter((ext) => {
        return ext.name === file.name;
      });
      
      if (verifyExists.length === 0) {
        uploadedFiles.push({
          file,
          id: `${moment(new Date).format('YYYYMMDDHHmmss')}-${index}`,
          name: file.name,
          preview: URL.createObjectURL(file),
          progress: 0,
          uploaded: false,
          error: false,
          size: formatBytes(file.size)
        });
      } else {
        ShowSnack('Imagem já selecionada', enqueueSnackbar, 'warning');
      }
    });

    setImagensSelecionadas([...imagensSelecionadas, ...uploadedFiles]);

    uploadedFiles.forEach(processUpload);
  }

  const handleDelete = async (id) => {
    await api
      .delete(`registros-fotografico/${id}`)
      .then(() => {
        setImagensSelecionadas(imagensSelecionadas.filter(file => file.id !== id));
      })
      .catch(() => {
        ShowSnack('[Erro]: Erro ao excluir!', enqueueSnackbar, 'error');
      });
  }

  const nextStage = () => {
    setActiveStep(2);
  }

  useEffect(() => {
    api
      .post('registros-fotografico/acessar-imagens', { id_portifolio: idPortifolio })
      .then(({ data }) => {
        setImagensSelecionadas(data.map((file, index) => {
          return {
            file: undefined,
            id: file.id,
            name: `Imagem ${index+1}`,
            preview: file.linkAccess,
            progress: 100,
            uploaded: true,
            error: false,
            size: null
          }
        }))
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  return (
    <fieldset className={classes.areaFieldset}>
      <legend className={classes.legendTitle}>
        Registros Fotográficos
      </legend>

      <Grid container>
        <Grid item xs={12} className="next">
          <div />
          
          <button
            className="next_stage"
            onClick={() => nextStage()}
          >
            Próxima Etapa <ArrowForward />
          </button>
        </Grid>

        {getLengthImagesSelected() < 4 && (
          <Grid item xs={12}>
              <SeletorArquivos
                onUpload={handleUpload}
              />
          </Grid>
        )}

        { !!imagensSelecionadas.length && (
          <ItemsFiles files={imagensSelecionadas} onDelete={handleDelete} />
        )}
      </Grid>

      {/* Confirmar envio */}
      <DialogConfirmEnvio
        open={openConfirm}
        setOpen={setOpenConfirm}
        actionButtonConfirm={nextStage}
      />
    </fieldset>
  )
}
