import React from 'react';
import {
  Document,
  Page,
  PDFViewer,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  Link,
  PDFDownloadLink
} from '@react-pdf/renderer';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import latoBold from '../../assets/fonts/Lato-Bold.ttf';
import latoRegular from '../../assets/fonts/Lato-Regular.ttf';
import assinatura from '../../assets/images/pdf/assinatura.png';

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: latoRegular
    },
    {
      src: latoBold,
      fontWeight: 'bold'
    }
  ]
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    // backgroundColor: '#',
    fontSize: '12pt',
    fontFamily: 'Lato'
  },
  section: {
    margin: 10,
    padding: 10,
    textAlign: 'justify'
  },
  viewAssinatura: {
    flexDirection: 'row',
    margin: 10,
    padding: 10
  },
  textoPequeno: {
    fontSize: '9pt'
  }
});
const MyDocument = ({ nome, cpf, matricula, curso, codeValidation }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image cache src="/static/images/pdf/cabecalho.jpg" />
        <View style={[styles.section, { textAlign: 'right' }]}>
          <Text>Sobral - CE, {moment().format('LL')}</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>
            Assunto: Visita técnica
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={{ fontWeight: 'bold' }}>Prezado (a) Sr. (a),</Text>
        </View>
        <View style={styles.section}>
          <Text style={{ textIndent: 32 }}>
            Ao cumprimentarmos V. S.a, solicitamos autorização para que o aluno,{' '}
            <Text style={{ fontWeight: 'bold' }}>{nome}</Text>, CPF de Nº {cpf}{' '}
            e número de matrícula {matricula}, realize Visita Técnica referente
            ao curso de {curso} do Centro Universitário Inta – UNINTA - Polo
            Digital, que tem como objetivo propiciar a realização de atividades
            presenciais de natureza prática em ambientes profissionais.
          </Text>
          <Text style={{ textIndent: 32 }}>
            No azo, certos de contar com apoio de V.S.a, externamos votos de
            estima e elevada consideração.
          </Text>
        </View>
        <View style={styles.section}>
          <Text>Atenciosamente,</Text>
        </View>
        <View style={styles.viewAssinatura}>
          <View style={{ flex: 1, textAlign: 'center' }}>
            <View style={{ alignItems: 'center' }}>
              <Image
                style={{ height: 100, width: 100 }}
                cache
                src={assinatura}
              />
              <Text
                style={[
                  styles.textoPequeno,
                  {
                    fontWeight: 'bold',
                    marginTop: -32,
                    textAlign: 'left'
                  }
                ]}
              >
                IES
              </Text>
            </View>
            <View style={{ marginTop: 32 }}>
              <Text>______________________________________</Text>
              <Text style={[styles.textoPequeno, { fontWeight: 'bold' }]}>
                Aluno
              </Text>
            </View>
          </View>
          <View
            style={[
              {
                border: 1,
                flex: 1,
                padding: 8
              },
              styles.textoPequeno
            ]}
          >
            <Text style={{ textAlign: 'center' }}>
              Autorizo a realização da visita técnica conforme a solicitação do
              Curso de {curso}.{'\n'}
              {'\n'}
              ______ de ________________ de ____________.
              {'\n'}
              {'\n'}
              {'\n'}
              {'\n'}
              ________________________________________________{'\n'}
              Assinatura
            </Text>
          </View>
        </View>
        <View style={[{ textAlign: 'center' }, styles.textoPequeno]}>
          <Text>
            Para validar esse documenteo acesse {'\n'}
            https://ambienteprofissional.uninta.edu.br/validacao
          </Text>
          <Image
            style={{ width: '50px', alignSelf: 'center' }}
            cache
            src="/static/images/pdf/validate_qr.png"
          />
          <Text style={{ fontWeight: 'bold' }}>
            Código de Validação: {codeValidation}
          </Text>
        </View>

        <View
          style={{
            justifyContent: 'flex-end',
            flexGrow: 1
          }}
        >
          <Image cache src="/static/images/pdf/rodape.jpg" />
        </View>
      </Page>
    </Document>
  );
};
export default function CartaDeAutorizacao({
  nome,
  cpf,
  matricula,
  curso,
  codeValidation,
  children
}) {
  return (
    <PDFDownloadLink
      fileName={`carta_visita_${matricula}.pdf`}
      style={{ width: '100%', marginRight: 8 }}
      document={
        <MyDocument
          nome={nome}
          cpf={cpf}
          matricula={matricula}
          curso={curso}
          codeValidation={codeValidation}
        />
      }
    >
      {children}
    </PDFDownloadLink>
  );
}
