import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  CardHeader,
  CardActions
} from '@material-ui/core';
import Page from 'src/components/Page';
import {
  Check,
  CheckCircleOutline,
  CloudUpload,
  Home,
  RecordVoiceOver
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import showSnack from '../../utils/snacks';
import AuthContext from 'src/contexts/AuthContext';
import api from '../../services/api';

import SelectRadio from './components/selectRadio';
import SelectCheckbox from './components/selectCheckbox';
import SelectText from './components/seletectText';
import DialogTermoAceite from './components/DialogTermoAceite';

import FormManut from '../../assets/lotties/form_manut.gif';

import './styles.css';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgLogin: {
    [theme.breakpoints.down('sm')]: {
      height: 100,
      width: 100
    },
    [theme.breakpoints.up('sm')]: {
      height: 150,
      width: 190
    }
  },
  container: {
    background: '#eee',
    padding: 10,
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '-1px -9px 40px #888',
    marginBottom: 20
  },
  gridCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px dashed #bbb'
  },
  gridQuestion: {
    padding: 15
  },
  question: {
    color: '#444',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  answer: {
    paddingLeft: 50
  },
  qtAberta: {
    '&:focus': {
      outlineColor: '#b00f14'
    },
    width: '95%',
    border: '1px solid #ddd',
    borderRadius: 8,
    background: '#ddd',
    padding: 10,
    fontSize: 16,
    resize: 'none',
    fontFamily: 'Roboto'
  },
  btnSalvar: {
    margin: 5,
    backgroundColor: '#b00f14',
    color: '#fff',
    '&:hover': {
      background: '#820d10',
      transition: 'background-color 0.5s'
    }
  },
  btnVoltar: {
    margin: 5,
    backgroundColor: '#e5b31a',
    color: '#444',
    '&:hover': {
      backgroundColor: '#c19716',
      transition: 'background-color 0.2s'
    }
  },
  inputFile: {
    display: 'none'
  },
  labelFile: {
    padding: '10px 5px',
    width: '100%',
    backgroundColor: '#e5b31a',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    cursor: 'pointer',
    margin: 10,
    borderRadius: 8,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#c19716',
      transition: 'background-color 0.2s'
    }
  },
  labelFileSelected: {
    padding: '10px 5px',
    width: '40%',
    backgroundColor: '#0cb96e',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    marginTop: '10px',
    cursor: 'pointer',
    margin: 10,
    borderRadius: 8,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#0f9058',
      transition: 'background-color 0.2s'
    }
  },
  btnSelecionado: {
    padding: 16,
    margin: 5,
    background: '#38b61b',
    color: '#fff',
    '&:hover': {
      background: '#299111'
    }
  },
  btnSelecionar: {
    padding: 16,
    margin: 5,
    background: '#4d56a2',
    color: '#fff',
    '&:hover': {
      background: '#323a7c'
    }
  }
}));

const FormAcoes = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { ra } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const idSeminario = useParams().id_seminario;
  const course = sessionStorage.getItem('@c@cous@eea@');
  const [qtdQuestoes, setQtdQuestoes] = useState(0);
  const [enviouCartaAutorizacao, setCartaAutorizacao] = useState(false);
  const [carregarFormulario, setCarregarFormulario] = useState(false);
  const [recarregar, setRecarregar] = useState(false);

  const [carta, setCarta] = useState([]);

  const [questoes, setQuestoes] = useState([]);
  const [numResposta, setNumResposta] = useState({});

  const [formulario, setFormulario] = useState([]);

  const [openConfirm, setConfirm] = useState(false);
  const [openTermoAceite, setOpenTermoAceite] = useState(false);

  function updateNumQuestao(valor) {
    setNumResposta(oldValue => {
      return { ...oldValue, ...valor };
    });
  }

  const salvar = () => {
    const respondidas = Object.keys(numResposta);
    let count = 0;

    respondidas.map(dados => {
      const { alternativas, tipo_questao } = numResposta[dados];

      if (tipo_questao === 'caixa_de_selecao') {
        const getAlternativas = Object.keys(alternativas);

        const tamanhoIsChecked = getAlternativas.filter(alternativa => {
          return alternativas[alternativa]?.value;
        }).length;

        if (tamanhoIsChecked >= 1) {
          count++;
        }
      } else {
        if (numResposta[dados].value) {
          count++;
        }
      }
    });

    if (count === qtdQuestoes) {
      api
        .post('respostas_questionarios', {
          ra,
          respostas: numResposta
        })
        .then(() => {
          setConfirm(false);
          navigate('/app/enviar-acoes');
          showSnack('Envio das respostas feito com sucesso!', enqueueSnackbar);
        })
        .catch(e => {
          showSnack('Oops! Houve um erro!', enqueueSnackbar, 'error');
        });
    } else {
      showSnack(
        'Necessário preencher todos campos!',
        enqueueSnackbar,
        'warning'
      );
    }
  };

  useEffect(() => {
    api
      .post('respostas/getByRaAndIdSeminario', {
        ra,
        id_seminario: idSeminario
      })
      .then(({ data }) => {
        if (data.length > 0) {
          if (
            (data[0]?.seminario.curso.title_curso).toUpperCase() ===
            course.toUpperCase()
          ) {
            if (data[0]?.seminario.cartaAutoriza[0]?.path_carta_autorizacao) {
              setCartaAutorizacao(true);
              setCarta(data[0]?.seminario.cartaAutoriza);
              if (data[0]?.id_formulario) {
                if (data[0]?.questoes.length > 0) {
                  navigate('/app');
                } else {
                  setCarregarFormulario(true);
                }
              } else {
                setCarregarFormulario(false);
              }
            } else {
              setCartaAutorizacao(false);
            }
          } else {
            navigate('/app');
          }
        } else {
          navigate('/app');
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, [carregarFormulario, recarregar]);

  useEffect(() => {
    api
      .get(`forms/with-seminario/${idSeminario}`)
      .then(({ data }) => {
        setQtdQuestoes((data[0]?.questoes).length);
        setFormulario(data[0]);
        setQuestoes(
          data[0]?.questoes?.map((questoes, index) => {
            return (
              <Fragment key={questoes.id_questao}>
                <Grid item xs={12} className={classes.gridQuestion}>
                  <Typography className={classes.question}>
                    {`${index + 1} - ${questoes.title_questao}`}
                  </Typography>
                </Grid>

                {questoes.tipo_questao === 'multipla_escolha' ? (
                  <SelectRadio
                    alternativas={questoes.alternativas}
                    setValor={updateNumQuestao}
                    index={index}
                    tipoQuestao={questoes.tipo_questao}
                    idQuestao={questoes.id_questao}
                  />
                ) : questoes.tipo_questao === 'caixa_de_selecao' ? (
                  <SelectCheckbox
                    alternativas={questoes.alternativas}
                    setValor={updateNumQuestao}
                    index={index}
                    tipoQuestao={questoes.tipo_questao}
                    idQuestao={questoes.id_questao}
                  />
                ) : questoes.tipo_questao === 'paragrafo' ? (
                  <SelectText
                    index={index}
                    setValor={updateNumQuestao}
                    tipoQuestao={questoes.tipo_questao}
                    idQuestao={questoes.id_questao}
                  />
                ) : null}
              </Fragment>
            );
          })
        );
      })
      .catch(e => {
        console.log(e);
      });
  }, []);

  return (
    <Page
      className={classes.root}
      title="Roteiro para a visita técnica de imersão no ambiente profissional"
    >
      <Container>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.gridCenter}>
            <img
              alt="Logo AIAMIS"
              className={classes.imgLogin}
              src="/favicon.png"
            />

            <Typography
              style={{
                color: '#444',
                fontSize: '1.8rem',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              {formulario.title_formulario}
            </Typography>
          </Grid>

          {enviouCartaAutorizacao ? (
            <>
              {carregarFormulario ? (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      background: '#e5b31a',
                      margin: 20,
                      border: '1px solid #888',
                      borderRadius: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: 10,
                      color: '#444'
                    }}
                  >
                    <RecordVoiceOver style={{ fontSize: 40 }} />
                    <Typography
                      style={{
                        color: '#444',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        fontFamily: 'Lato'
                      }}
                    >
                      Orientações Gerais:
                    </Typography>
                    <Typography
                      style={{
                        color: '#444',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        textAlign: 'justify',
                        fontFamily: 'Lato'
                      }}
                    >
                      {formulario.orientacoes}
                    </Typography>
                  </Grid>

                  {questoes.length > 0 ? (
                    questoes
                  ) : (
                    <Grid item xs={12} className={classes.gridCenter}>
                      <Typography
                        style={{
                          color: '#b00f14',
                          fontSize: '2rem',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          fontFamily: 'Lato'
                        }}
                      >
                        Formulário em construção.
                        <br />
                        Volte outra hora, por favor!
                      </Typography>
                      <img
                        src={FormManut}
                        style={{ height: '25rem', width: '50%' }}
                      />
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item xs={12} className={classes.gridCenter}>
                  <Typography
                    style={{
                      color: '#b00f14',
                      fontSize: '2rem',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontFamily: 'Lato'
                    }}
                  >
                    Formulário em construção.
                    <br />
                    Volte outra hora, por favor!
                  </Typography>
                  <img
                    src={FormManut}
                    style={{ height: '25rem', width: '50%' }}
                  />
                </Grid>
              )}
            </>
          ) : (
            <Grid item xs={12} style={{ padding: 10 }}>
              <Card
                style={
                  enviouCartaAutorizacao
                    ? { border: '1px solid #38b61b' }
                    : { border: '1px solid #4d56a2' }
                }
              >
                <CardHeader
                  title={
                    <Typography
                      style={{
                        fontSize: 20,
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        textAlign: 'center'
                      }}
                      color="primary"
                    >
                      Termo de Aceite Assinado
                    </Typography>
                  }
                />

                <CardActions>
                  <Grid container>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        fullWidth
                        className={
                          enviouCartaAutorizacao
                            ? classes.btnSelecionado
                            : classes.btnSelecionar
                        }
                        onClick={() => {
                          setOpenTermoAceite(true);
                        }}
                      >
                        {enviouCartaAutorizacao ? 'Selecinado' : 'Selecionar'}
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            style={{ textAlign: 'center', borderTop: '1px dashed #bbb' }}
          >
            <Button
              className={classes.btnVoltar}
              onClick={() => navigate('/app')}
            >
              <Home />
              &nbsp;&nbsp; Início
            </Button>
            {enviouCartaAutorizacao ? (
              <Button
                className={classes.btnSalvar}
                onClick={() => setConfirm(true)}
              >
                <Check />
                &nbsp;&nbsp; Salvar
              </Button>
            ) : null}
          </Grid>

          <Grid item xs={12} style={{ marginTop: 20, textAlign: 'center' }}>
            <Typography
              style={{ color: '#444', fontSize: '0.7rem', fontWeight: 'bold' }}
            >
              Copyright &copy; DIRIN - PROEAD - AIAMIS - 2020.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={openConfirm}
        onClose={() => setConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirma as respostas?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Antes de confirmar revise as suas respostas!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => salvar()} className={classes.btnSalvar}>
            Sim!
          </Button>
          <Button
            variant="outlined"
            onClick={() => setConfirm(false)}
            color="primary"
          >
            Não!
          </Button>
        </DialogActions>
      </Dialog>

      <DialogTermoAceite
        open={openTermoAceite}
        setOpen={setOpenTermoAceite}
        idSeminario={idSeminario}
        ra={ra}
        recarregar={recarregar}
        setRecarregar={setRecarregar}
      />
    </Page>
  );
};

export default FormAcoes;
