import React from 'react';
import {
  Button, CircularProgress, Grid, Typography
} from '@material-ui/core';
import './styles.css';
import api from 'src/services/api';
import { Block, CheckCircleOutline } from '@material-ui/icons';

export default function ValidacaoDeDocumentos() {
  const [aluno, setAluno] = React.useState({ nome: '', ra: '' });
  const [codigo, setCodigo] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [tipoDocumento, setTipoDocumento] = React.useState('');
  const [isValid, setIsValid] = React.useState({
    isValid: false,
    posBusca: false
  });
  return (
    <form
      className="form_validacao_documento"
      onSubmit={async (e) => {
        e.preventDefault();

        if (codigo) {
          setLoading(true);
          setIsValid({ ...isValid, posBusca: false });
          const { data } = await api.post('aluno/pdf/valida', {
            token: codigo,
            tipo_documento: 'Carta de visita'
          });
          setLoading(false);
          setAluno({ nome: data.nome, ra: data.ra });
          setTipoDocumento(data.typeToken);
          setIsValid({ isValid: data.isValid, posBusca: true });
        } else {
          alert('Todos os campos são abrigatórios');
        }
      }}
    >
      <div id="div_codigo" style={{ display: 'flex', flexDirection: 'column' }}>
        <label className="lab" htmlFor="codigo_validacao">
          Código de Validação
        </label>
        <input
          onChange={(e) => {
            setCodigo(e.target.value);
          }}
          value={codigo}
          placeholder="Código de Validação"
          type="text"
          id="codigo_validacao"
          name="codigo_validacao"
          style={{ textAlign: 'center' }}
        />
      </div>

      <Button variant="contained" type="submit">
        {loading ? <CircularProgress /> : 'Validar'}
      </Button>
      {!isValid.isValid && isValid.posBusca && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#f00'
          }}
        >
          <Block style={{ fontSize: 64, marginTop: 32 }} />
          <Typography variant="h2">Certificado Inválido</Typography>
        </div>
      )}
      {isValid.isValid && isValid.posBusca && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#0f0'
          }}
        >
          <CheckCircleOutline style={{ fontSize: 64, marginTop: 32 }} />
          <Typography variant="h2">Certificado Válido</Typography>
          <Typography style={{ color: 'black' }} variant="h2">
            Aluno:
            {' '}
            {aluno.nome}
          </Typography>
          <Typography style={{ color: 'black' }} variant="h2">
            Matrícula:
            {' '}
            {aluno.ra}
          </Typography>
          <Typography style={{ color: 'black' }} variant="h2">
            Documento:
            {' '}
            {tipoDocumento}
          </Typography>
        </div>
      )}
    </form>
  );
}
