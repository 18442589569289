import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, makeStyles, IconButton } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import Logo from 'src/components/Logo';
import AuthContext from 'src/contexts/AuthContext';

const useStyles = makeStyles({
  root: {},
  toolbar: {
    height: 64,
    backgroundColor: '#b00f14',
    borderBottom: '5px solid #e5b31a'
  },
  flexGrow: {
    flexGrow: 1
  }
});

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  const { setRa } = React.useContext(AuthContext);

  const handleLogout = () => {
    setRa('');
    sessionStorage.removeItem('@u@ap@aut@r@a');
    sessionStorage.removeItem('@c@cous@eea@');
    sessionStorage.removeItem('@c@couss@eea@alu');
    window.location = '/';
  };

  return (
    <>
      <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
        <Toolbar className={classes.toolbar}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>

          <div className={classes.flexGrow} />
          <IconButton
            style={{ float: 'inline-end' }}
            edge="end"
            color="inherit"
            onClick={handleLogout}
          >
            <ExitToApp />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
