import React from 'react';
import {
    Grid,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';

function SelectCheckbox({ alternativas = [], setValor, index, tipoQuestao, idQuestao }) {
    const [state, setState] = React.useState({});
    return (
        <>
            <Grid item xs={12} className="answer">
                {
                    alternativas.map((alt, i) => {
                        return (
                            <FormControlLabel
                            checked={state["alt"+i]?.isChecked ? state["alt"+i].isChecked : false}
                            key={alt.id_alternativa}
                            control={
                                <Checkbox
                                    color="primary"
                                    value={alt.text_alternativa}
                                    onChange={(event)=>{
                                        let objeto;
                                        if(!(event.target.checked)){
                                            objeto = {...state,  ['alt'+i]: undefined};
                                        }else
                                        {
                                            objeto =  {...state, ['alt'+i]: {...state['alt'+i], isChecked: event.target.checked, value: event.target.value}};
                                        }
                                        setState(objeto)
                                        setValor({['questao'+index]: { tipo_questao: tipoQuestao, id: idQuestao, alternativas: objeto}});
                                    }}
                                />
                            }
                            label={alt.text_alternativa}
                            />
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default SelectCheckbox;
