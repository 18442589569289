import React, { useCallback } from 'react';
import {
  Button,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import showSnack from 'src/utils/snacks';

import './styles.scss';

export default function SeletorArquivos({ onUpload, type = 'registro_ft' }) {
  const { enqueueSnackbar } = useSnackbar();
  
  const onDrop = (acceptedFiles) => {
    onUpload(acceptedFiles);
  };
  
  const onDropRejected = useCallback((e) => {
    e[0].errors.map((error) => {
      console.log(error);
      if (error.code === 'file-too-large') {
        showSnack('O arquivo enviado supera o limite de 45MB', enqueueSnackbar, 'warning');
      }

      if (error.code === 'too-many-files') {
        showSnack('Permitido somente 4 arquivos.', enqueueSnackbar, 'warning');
      }
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: type === 'registro_ft' ? 4 : 0,
    maxSize: 45 * 1024 * 1024,
    multiple: true,
    onDropRejected,
    accept: type === 'registro_ft' ? ['.png', '.jpeg', '.jpg'] : ['.pdf', '.png', '.jpeg', '.jpg']
  });

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    paddingTop: '30px',
    paddingBottom: '30px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#b0b0b0',
    borderStyle: 'dashed',
    backgroundColor: '#e0e0e0',
    color: '#000000aa',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const activeStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  return (
    <div id="container-seletor-arquivo">
      <div
        {...getRootProps({
          style: {
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
          },
        })}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Solte o arquivo aqui.</p>
        ) : (
          <p id="mensagem-arquivo">
            Arraste e solte seus arquivos aqui
            <span>Ou</span>
            <Button variant="contained">
              Clique para selecionar os arquivos
            </Button>
            <span>Tamanho máximo: 5Mb</span>
          </p>
        )}
      </div>
    </div>
  );
}
