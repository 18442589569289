import React from 'react';
import {
    Grid,
    TextareaAutosize,
    Container,
    Typography,
} from '@material-ui/core';

function SelectText({ setValor, index, tipoQuestao, idQuestao }) {
    const [state, setState] = React.useState('');

    return (
        <>
            <Grid item xs={12} className="answer">
                <TextareaAutosize
                maxLength="2000"
                rowsMin={5}
                rowsMax={5}
                placeholder="Escreva aqui..."
                className="qtAberta"
                value={state}
                onChange={(e) => {
                    setState(e.target.value);
                    setValor({['questao'+index]: {value: e.target.value, tipo_questao: tipoQuestao, id: idQuestao}});
                }}
                />
                <Container>
                    <Typography style={{ textAlign: 'right', fontSize: 12, fontWeight: 'bold', color: '#444', marginRight: 35 }}>{state.length} de 2000 caracteres</Typography>
                </Container>
            </Grid>
        </>
    )
}

export default SelectText;