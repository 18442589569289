import { func } from 'prop-types';
import React from 'react';

export default React.createContext({
  isAuth: false,
  ra: '',
  loading: true,
  setRa: function() {
  }
});
