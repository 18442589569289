import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthLayout from 'src/layouts/AuthLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import Form from 'src/views/Form';
import FormFinished from 'src/views/FormFinished';
import Dashboard from 'src/views/Dashboard';
import DetalhesCurso from 'src/views/DetalhesCurso';
import EnviarAcoes from 'src/views/EnviarAcoes';
import CartaDeAutorizacao from './views/CartaDeAutorizacao';
import ValidacaoDeDocumentos from './views/ValidacaoDeDocumentos';
import FormAcoes from 'src/views/FormAcoes';
import EnvioCartaAutorizacao from 'src/views/EnvioCartaAutorizacao';
import ListSeminarioPortifolio from 'src/views/ListSeminarioPortifolio';
import PortifolioForm from 'src/views/PortifolioForm';
import GeraPDF from './views/PortifolioForm/components/GeraPDF';
import PortifolioFormEdit from 'src/views/PortifolioFormEdit';

const routes = [
  {
    path: 'app',
    element: <AuthLayout />,
    children: [
      { path: '', element: <Dashboard /> },
      { path: 'form', element: <Form /> },
      { path: 'form-finished', element: <FormFinished /> },
      { path: 'detalhes-curso', element: <DetalhesCurso /> },
      { path: 'enviar-acoes', element: <EnviarAcoes /> },
      { path: 'form-acoes/:id_seminario', element: <FormAcoes /> },
      { path: 'carta-autorizacao', element: <CartaDeAutorizacao /> },
      { path: 'envio-carta/:id_seminario', element: <EnvioCartaAutorizacao /> },
      { path: 'list-portifolio', element: <ListSeminarioPortifolio /> },
      { path: 'form-portifolio/:id_seminario', element: <PortifolioForm /> },
      { path: 'form-portifolio/meu-portifolio', element: <GeraPDF /> },
      {
        path: 'edit-portifolio/:id_seminario',
        element: <PortifolioFormEdit />
      },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <LoginView /> },
      { path: 'validacao', element: <ValidacaoDeDocumentos /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
