import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Add, Close, Description } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import ShowSnack from '../../../utils/snacks';
import api from '../../../services/api';

import SeletorArquivos from './SeletorArquivos';
import { Upload } from 'react-feather';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  areaFieldset: {
    padding: 4,
    border: '1px solid #444',
    width: '100%',
    margin: '10px 0px',
    borderRadius: 4,
    background: '#fafafa'
  },
  legendTitle: {
    padding: '0px 8px',
    textTransform: 'uppercase',
    fontFamily: 'roboto',
    fontWeight: 700,
    fontSize: 12,
    color: '#222'
  }
}));

function DialogTermoAceite({
  idSeminario = 0,
  ra = 0,
  open,
  setOpen,
  recarregar = true,
  setRecarregar = () => {},
  verificaCarta,
  idCarta
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [carta, setCarta] = useState([]);

  const [progressState, setProgressState] = useState(0);

  const salvar = () => {
    if (carta.length > 0) {
      const formData = new FormData();
      formData.append('path_carta', carta[0]);
      formData.append('id', idSeminario);
      formData.append('ra', ra);
      api
        .post('set-carta', formData, {
          onUploadProgress: event => {
            let progress = Math.round((event.loaded * 100) / event.total);
            setProgressState(progress);
          }
        })
        .then(e => {
          setRecarregar(!recarregar);
          setOpen(false);
          setCarta([]);
          ShowSnack(
            'Envio da carta de autorização feita com sucesso!',
            enqueueSnackbar
          );
          setProgressState(0);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      ShowSnack('Necessário selecionar 1 arquivo!', enqueueSnackbar, 'warning');
    }
  };

  const atualizarTermo = () => {
    if (carta.length > 0) {
      const formData = new FormData();
      formData.append('path_carta', carta[0]);
      api
        .put(`carta-autoriza/${idCarta}`, formData, {
          onUploadProgress: event => {
            let progress = Math.round((event.loaded * 100) / event.total);
            setProgressState(progress);
          }
        })
        .then(e => {
          setOpen(false);
          setCarta([]);
          ShowSnack(
            'Alteração da carta de autorização feita com sucesso!',
            enqueueSnackbar
          );
          setProgressState(0);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      ShowSnack('Necessário selecionar 1 arquivo!', enqueueSnackbar, 'warning');
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setCarta([]);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={{ background: '#b00f14' }}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              flexDirection: 'row',
              width: '100%'
            }}
          >
            <Box style={{ flex: 1 }} />

            <Box style={{ flex: 1 }}>
              <Typography
                style={{
                  color: '#fff',
                  textAlign: 'center',
                  fontSize: 20,
                  fontWeight: 'bold'
                }}
              >
                {verificaCarta
                  ? 'Alterar Termo de Aceite'
                  : 'Selecionar Termo de Aceite'}
              </Typography>
            </Box>

            <Box
              style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                variant="text"
                style={{ color: '#fff' }}
                onClick={() => {
                  if (verificaCarta) {
                    atualizarTermo();
                  } else {
                    salvar();
                  }
                }}
                startIcon={<Upload />}
              >
                {verificaCarta ? 'Editar' : 'Enviar'}
              </Button>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          {progressState > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                style={{ fontSize: 18, fontWeight: 'bold', color: '#b00f14' }}
              >
                Por favor, aguarde...
              </Typography>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <CircularProgress />
              </Box>
            </div>
          ) : (
            <SeletorArquivos state={carta} setState={setCarta} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DialogTermoAceite;
