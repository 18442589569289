import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  TextField,
  Checkbox,
  TextareaAutosize,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import Page from 'src/components/Page';
import { Check } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import showSnack from '../../utils/snacks';
import AuthContext from 'src/contexts/AuthContext';
import api from '../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgLogin: {
    [theme.breakpoints.down('sm')]: {
      height: 100,
      width: 100
    },
    [theme.breakpoints.up('sm')]: {
      height: 150,
      width: 190
    }
  },
  container: {
    background: '#eee',
    padding: 10,
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '-1px -9px 40px #888',
    marginBottom: 20
  },
  gridCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px dashed #bbb'
  },
  gridQuestion: {
    padding: 15
  },
  question: {
    color: '#444',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  answer: {
    paddingLeft: 50
  },
  qtAberta: {
    '&:focus': {
      outlineColor: '#b00f14'
    },
    width: '95%',
    border: '1px solid #ddd',
    borderRadius: 8,
    background: '#ddd',
    padding: 10,
    fontSize: 16,
    resize: 'none',
    fontFamily: 'Roboto'
  },
  btnSalvar: {
    margin: 5,
    backgroundColor: '#b00f14',
    color: '#fff',
    '&:hover': {
      background: '#820d10',
      color: '#fff',
      transition: 'background-color 0.5s'
    }
  }
}));

const Form = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { ra } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const [openConfirm, setConfirm] = useState(false);

  useEffect(() => {
    if (ra) {
      api
        .get('answer/1', {
          params: { ra }
        })
        .then(({ data }) => {
          if (data.question7) {
            return navigate('/app');
          }
        })
        .catch(e => console.log(e));
    }
  }, [ra]);

  const [state, setState] = useState({
    question1: '',
    question2: '',
    question3: '',
    question4: '',
    question5: '',
    question6: '',
    question7: '',
    question8: '',
    question9: '',
    question10: '',
    question11: '',
    question12: '',
    question13: '',
    question14: '',
    question15: '',
    question16: '',
    question17: '',
    ra: ''
  });

  const [question3Oter, setQuestion3Oter] = useState('');

  const [question8A, setQuestion8A] = useState('');
  const [question8B, setQuestion8B] = useState('');
  const [question8C, setQuestion8C] = useState('');

  const [question9A, setQuestion9A] = useState('');
  const [question9B, setQuestion9B] = useState('');
  const [question9C, setQuestion9C] = useState('');
  const [question9D, setQuestion9D] = useState('');
  const [question9E, setQuestion9E] = useState('');
  const [question9F, setQuestion9F] = useState('');
  const [question9G, setQuestion9G] = useState('');
  const [question9H, setQuestion9H] = useState('');
  const [question9Oter, setQuestion9Oter] = useState('');

  const [question10A, setQuestion10A] = useState('');
  const [question10B, setQuestion10B] = useState('');
  const [question10C, setQuestion10C] = useState('');
  const [question10D, setQuestion10D] = useState('');
  const [question10Oter, setQuestion10Oter] = useState('');

  const handleChange8 = event => {
    setState({
      ...state,
      question8: { [event.target.name]: event.target.checked }
    });
  };

  const salvar = () => {
    if (
      state.question1 &&
      state.question2 &&
      state.question3 &&
      state.question4 &&
      state.question5 &&
      state.question6 &&
      state.question7 &&
      state.question11 &&
      state.question12 &&
      state.question13 &&
      state.question14.length > 0 &&
      state.question14.length <= 2000 &&
      state.question15.length > 0 &&
      state.question15.length <= 2000 &&
      state.question16 &&
      state.question17
    ) {
      const Oter9 = question9H ? question9Oter : '';
      const Oter10 = question10D ? question10Oter : '';

      api
        .post('/answer', {
          ra,
          question1: state.question1,
          question2: state.question2,
          question3: state.question3,
          question4: state.question4,
          question5: state.question5,
          question6: state.question6,
          question7: state.question7,
          question8: `${question8A}¢¬${question8B}¢¬${question8C}`,
          question9: `${question9A}¢¬${question9B}¢¬${question9C}¢¬${question9D}¢¬${question9E}¢¬${question9F}¢¬${question9G}¢¬${Oter9}`,
          question10: `${question10A}¢¬${question10B}¢¬${question10C}¢¬${Oter10}`,
          question11: state.question11,
          question12: state.question12,
          question13: state.question13,
          question14: state.question14,
          question15: state.question15,
          question16: state.question16,
          question17: state.question17
        })
        .then(() => {
          showSnack('Questionário respondido com sucesso!', enqueueSnackbar);
          navigate('/app/form-finished');
        })
        .catch(() =>
          showSnack(
            'Oops! Ocorreu um erro ao mandar suas respostas!',
            enqueueSnackbar,
            'error'
          )
        );
    } else {
      showSnack('Preencha todos os campos!', enqueueSnackbar, 'warning');
    }
  };

  return (
    <Page className={classes.root} title="Questionário Socioprofissional">
      <Container>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.gridCenter}>
            <img
              alt="Logo AIAMIS"
              className={classes.imgLogin}
              src="/favicon.png"
            />

            <Typography
              style={{
                color: '#444',
                fontSize: '2.5rem',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              Questionário Socioprofissional
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              01* - Você exerce alguma atividade remunerada?
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControl component="fieldset">
              <RadioGroup
                column
                aria-label="position"
                name="position"
                defaultValue="top"
                value={state.question1}
                onChange={e =>
                  setState({ ...state, question1: e.target.value })
                }
              >
                <FormControlLabel
                  value="Não"
                  control={<Radio color="primary" />}
                  label="Não"
                />
                <FormControlLabel
                  value="Sim, em tempo parcial (até vinte horas semanais) "
                  control={<Radio color="primary" />}
                  label="Sim, em tempo parcial (até vinte horas semanais) "
                />
                <FormControlLabel
                  value="Sim, em tempo integral (mais de trinta horas semanais) "
                  control={<Radio color="primary" />}
                  label="Sim, em tempo integral (mais de trinta horas semanais) "
                />
                <FormControlLabel
                  value="Sim, mas se trata de trabalho eventual"
                  control={<Radio color="primary" />}
                  label="Sim, mas se trata de trabalho eventual"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              02* - Seu trabalho tem alguma relação com o curso que você
              escolheu?
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                value={state.question2}
                onChange={e =>
                  setState({ ...state, question2: e.target.value })
                }
              >
                <FormControlLabel
                  value="Sim"
                  control={<Radio color="primary" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="Não"
                  control={<Radio color="primary" />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              03* - Ao ingressar na instituição você espera...
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControl component="fieldset">
              <RadioGroup
                column
                aria-label="position"
                name="position"
                defaultValue="top"
                value={state.question3}
                onChange={e =>
                  setState({ ...state, question3: e.target.value })
                }
              >
                <FormControlLabel
                  value="Ser preparado para ingressar no mercado de trabalho"
                  control={<Radio color="primary" />}
                  label="Ser preparado para ingressar no mercado de trabalho"
                />
                <FormControlLabel
                  value="Ser preparado para uma profissão no futuro"
                  control={<Radio color="primary" />}
                  label="Ser preparado para uma profissão no futuro"
                />
                <FormControlLabel
                  value="Ser preparado para enfrentar o cotidiano"
                  control={<Radio color="primary" />}
                  label="Ser preparado para enfrentar o cotidiano"
                />
                <FormControlLabel
                  value="Outros"
                  control={<Radio color="primary" />}
                  label="Outros"
                />
                <TextField
                  id="standard-basic"
                  disabled={state.question3 !== 'Outros'}
                  focused={state.question3 === 'Outros'}
                  value={question3Oter}
                  onChange={e => {
                    setQuestion3Oter(e.target.value);
                  }}
                  label="Escreva... "
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              04* - Fazer um curso superior significa?
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControl component="fieldset">
              <RadioGroup
                column
                aria-label="position"
                name="position"
                defaultValue="top"
                value={state.question4}
                onChange={e =>
                  setState({ ...state, question4: e.target.value })
                }
              >
                <FormControlLabel
                  value="Ter mais uma oportunidade de aprender"
                  control={<Radio color="primary" />}
                  label="Ter mais uma oportunidade de aprender"
                />
                <FormControlLabel
                  value="Ter mais oportunidade para ingressar no mercado de trabalho"
                  control={<Radio color="primary" />}
                  label="Ter mais oportunidade para ingressar no mercado de trabalho"
                />
                <FormControlLabel
                  value="Aprender mais conhecimentos sobre o curso escolhido por você"
                  control={<Radio color="primary" />}
                  label="Aprender mais conhecimentos sobre o curso escolhido por você"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              05* - É sua primeira graduação?
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                value={state.question5}
                onChange={e =>
                  setState({ ...state, question5: e.target.value })
                }
              >
                <FormControlLabel
                  value="Sim"
                  control={<Radio color="primary" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="Não"
                  control={<Radio color="primary" />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              06* - Você tem um local específico para seus estudos?
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                value={state.question6}
                onChange={e =>
                  setState({ ...state, question6: e.target.value })
                }
              >
                <FormControlLabel
                  value="Sim"
                  control={<Radio color="primary" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="Não"
                  control={<Radio color="primary" />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              07* - Você tem um horário definido para seus estudos?
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                value={state.question7}
                onChange={e =>
                  setState({ ...state, question7: e.target.value })
                }
              >
                <FormControlLabel
                  value="Sim"
                  control={<Radio color="primary" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="Não"
                  control={<Radio color="primary" />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              08 - Possui algum tipo de dificuldade para as disciplinas
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControlLabel
              value="Língua Portuguesa"
              checked={question8A === 'Língua Portuguesa'}
              onChange={e =>
                setQuestion8A(
                  question8A === 'Língua Portuguesa' ? '' : e.target.value
                )
              }
              control={<Checkbox color="primary" />}
              label="Língua Portuguesa"
            />

            <FormControlLabel
              value="Matemática"
              checked={question8B === 'Matemática'}
              onChange={e =>
                setQuestion8B(question8B === 'Matemática' ? '' : e.target.value)
              }
              control={<Checkbox color="primary" />}
              label="Matemática"
            />

            <FormControlLabel
              value="Inglês"
              checked={question8C === 'Inglês'}
              onChange={e =>
                setQuestion8C(question8C === 'Inglês' ? '' : e.target.value)
              }
              control={<Checkbox color="primary" />}
              label="Inglês"
            />
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              09 - Possui alguma dificuldade de aprendizagem?
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControlLabel
              value="Concentração"
              checked={question9A === 'Concentração'}
              onChange={e =>
                setQuestion9A(
                  question9A === 'Concentração' ? '' : e.target.value
                )
              }
              control={<Checkbox color="primary" />}
              label="Concentração"
            />
            <br />
            <FormControlLabel
              value="Interpretação"
              checked={question9B === 'Interpretação'}
              onChange={e =>
                setQuestion9B(
                  question9B === 'Interpretação' ? '' : e.target.value
                )
              }
              control={<Checkbox color="primary" />}
              label="Interpretação"
            />
            <br />
            <FormControlLabel
              checked={question9C === 'Assimilação'}
              value="Assimilação"
              onChange={e =>
                setQuestion9C(
                  question9C === 'Assimilação' ? '' : e.target.value
                )
              }
              control={<Checkbox color="primary" />}
              label="Assimilação"
            />
            <br />
            <FormControlLabel
              checked={question9D === 'Interação'}
              value="Interação"
              onChange={e =>
                setQuestion9D(question9D === 'Interação' ? '' : e.target.value)
              }
              control={<Checkbox color="primary" />}
              label="Interação"
            />
            <br />
            <FormControlLabel
              checked={question9E === 'Memória'}
              value="Memória"
              onChange={e =>
                setQuestion9E(question9E === 'Memória' ? '' : e.target.value)
              }
              control={<Checkbox color="primary" />}
              label="Memória"
            />
            <br />
            <FormControlLabel
              value="Baixo rendimento escolar"
              checked={question9F === 'Baixo rendimento escolar'}
              onChange={e =>
                setQuestion9F(
                  question9F === 'Baixo rendimento escolar'
                    ? ''
                    : e.target.value
                )
              }
              control={<Checkbox color="primary" />}
              label="Baixo rendimento escolar"
            />
            <br />
            <FormControlLabel
              value="Raciocínio Lógico Matemático"
              checked={question9G === 'Raciocínio Lógico Matemático'}
              onChange={e =>
                setQuestion9G(
                  question9G === 'Raciocínio Lógico Matemático'
                    ? ''
                    : e.target.value
                )
              }
              control={<Checkbox color="primary" />}
              label="Raciocínio Lógico Matemático"
            />
            <br />
            <FormControlLabel
              value="Outros"
              checked={question9H === 'Outros'}
              onChange={e =>
                setQuestion9H(question9H === 'Outros' ? '' : e.target.value)
              }
              control={<Checkbox color="primary" />}
              label="Outros"
            />
            <br />
            <TextField
              focused={question9H}
              disabled={!question9H}
              label="Escreva... "
              value={question9Oter}
              onChange={e => setQuestion9Oter(e.target.value)}
              style={{ width: '40%' }}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              10 - Possui alguma dificuldade de acessibilidade? (opcional)
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControlLabel
              value="Visual"
              checked={question10A === 'Visual'}
              onChange={e =>
                setQuestion10A(question10A === 'Visual' ? '' : e.target.value)
              }
              control={<Checkbox color="primary" name="checkedA" />}
              label="Visual"
            />
            <br />
            <FormControlLabel
              value="Auditiva"
              checked={question10B === 'Auditiva'}
              onChange={e =>
                setQuestion10B(question10B === 'Auditiva' ? '' : e.target.value)
              }
              control={<Checkbox color="primary" name="checkedA" />}
              label="Auditiva"
            />
            <br />
            <FormControlLabel
              value="Motora"
              checked={question10C === 'Motora'}
              onChange={e =>
                setQuestion10C(question10C === 'Motora' ? '' : e.target.value)
              }
              control={<Checkbox color="primary" name="checkedA" />}
              label="Motora"
            />
            <br />
            <FormControlLabel
              value="Outros"
              checked={question10D === 'Outros'}
              onChange={e =>
                setQuestion10D(question10D === 'Outros' ? '' : e.target.value)
              }
              control={<Checkbox color="primary" name="checkedA" />}
              label="Outros"
            />
            <br />
            <TextField
              id="standard-basic"
              disabled={!question10D}
              focused={question10D}
              label="Escreva... "
              value={question10Oter}
              onChange={e => setQuestion10Oter(e.target.value)}
              style={{ width: '40%' }}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              11* - Que razões o levaram a escolher esse curso?
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                value={state.question11}
                onChange={e =>
                  setState({ ...state, question11: e.target.value })
                }
              >
                <FormControlLabel
                  value="É meu sonho"
                  control={<Radio color="primary" />}
                  label="É meu sonho"
                />
                <FormControlLabel
                  value="É uma profissão promissora"
                  control={<Radio color="primary" />}
                  label="É uma profissão promissora"
                />
                <FormControlLabel
                  value="Me inspirei na profissão de meus pais"
                  control={<Radio color="primary" />}
                  label="Me inspirei na profissão de meus pais"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              12* - É a 1ª vez que participa do processo seletivo/vestibular do
              UNINTA?
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="top"
                value={state.question12}
                onChange={e =>
                  setState({ ...state, question12: e.target.value })
                }
              >
                <FormControlLabel
                  value="Sim"
                  control={<Radio color="primary" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="Não"
                  control={<Radio color="primary" />}
                  label="Não"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              13* - Qual foi o meio em que ficou sabendo do processo seletivo do
              Polo Digital UNINTA - EAD?
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControl component="fieldset">
              <RadioGroup
                column
                aria-label="position"
                name="position"
                defaultValue="top"
                value={state.question13}
                onChange={e =>
                  setState({ ...state, question13: e.target.value })
                }
              >
                <FormControlLabel
                  value="Panfleto do Polo Digital UNINTA"
                  control={<Radio color="primary" />}
                  label="Panfleto do Polo Digital UNINTA"
                />
                <FormControlLabel
                  value="Portal do Polo Digital do UNINTA"
                  control={<Radio color="primary" />}
                  label="Portal do Polo Digital do UNINTA"
                />
                <FormControlLabel
                  value="Jornal"
                  control={<Radio color="primary" />}
                  label="Jornal"
                />
                <FormControlLabel
                  value="Divulgação na Escola onde estuda"
                  control={<Radio color="primary" />}
                  label="Divulgação na Escola onde estuda"
                />
                <FormControlLabel
                  value="Cartaz"
                  control={<Radio color="primary" />}
                  label="Cartaz"
                />
                <FormControlLabel
                  value="Internet"
                  control={<Radio color="primary" />}
                  label="Internet"
                />
                <FormControlLabel
                  value="Instagram"
                  control={<Radio color="primary" />}
                  label="Instagram"
                />
                <FormControlLabel
                  value="Facebook"
                  control={<Radio color="primary" />}
                  label="Facebook"
                />
                <FormControlLabel
                  value="Rádio"
                  control={<Radio color="primary" />}
                  label="Rádio"
                />
                <FormControlLabel
                  value="Televisão"
                  control={<Radio color="primary" />}
                  label="Televisão"
                />
                <FormControlLabel
                  value="Por um familiar ou conhecido"
                  control={<Radio color="primary" />}
                  label="Por um familiar ou conhecido"
                />
                <FormControlLabel
                  value="Outros"
                  control={<Radio color="primary" />}
                  label="Outros"
                />
                <TextField
                  id="standard-basic"
                  disabled={state.question13 !== 'Outros'}
                  focused={state.question13 === 'Outros'}
                  label="Escreva... "
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              14* - A transformação digital pode ser definida como a
              incorporação do uso da tecnologia digital às soluções de problemas
              tradicionais. Explique como a transformação digital está
              impactando a sociedade e como está mudando a sua vida.
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <TextareaAutosize
              maxLength="2000"
              rowsMin={5}
              rowsMax={5}
              placeholder="Escreva aqui..."
              value={state.question14}
              onChange={e => setState({ ...state, question14: e.target.value })}
              className={classes.qtAberta}
            />
            <Container>
              <Typography
                style={{
                  textAlign: 'right',
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: '#444',
                  marginRight: 35
                }}
              >
                {state.question14.length} de 2000 caracteres
              </Typography>
            </Container>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              15* - Quais as competências que são exigidas aos profissionais
              para incorporarem a transformação digital?
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <TextareaAutosize
              maxLength="2000"
              rowsMin={5}
              rowsMax={5}
              placeholder="Escreva aqui..."
              value={state.question15}
              onChange={e => setState({ ...state, question15: e.target.value })}
              className={classes.qtAberta}
            />
            <Container>
              <Typography
                style={{
                  textAlign: 'right',
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: '#444',
                  marginRight: 35
                }}
              >
                {state.question15.length} de 2000 caracteres
              </Typography>
            </Container>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              16* - Qual o grau de competência que você julga ter para encarar o
              desafio da transformação digital a sua vida social.
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControl component="fieldset">
              <RadioGroup
                column
                aria-label="position"
                name="position"
                defaultValue="top"
                value={state.question16}
                onChange={e =>
                  setState({ ...state, question16: e.target.value })
                }
              >
                <FormControlLabel
                  value="Nenhuma competência"
                  control={<Radio color="primary" />}
                  label="Nenhuma competência"
                />
                <FormControlLabel
                  value="Reduzida competência"
                  control={<Radio color="primary" />}
                  label="Reduzida competência"
                />
                <FormControlLabel
                  value="Posso encarar"
                  control={<Radio color="primary" />}
                  label="Posso encarar"
                />
                <FormControlLabel
                  value="Muito competente"
                  control={<Radio color="primary" />}
                  label="Muito competente"
                />
                <FormControlLabel
                  value="Extremamente competente"
                  control={<Radio color="primary" />}
                  label="Extremamente competente"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.gridQuestion}>
            <Typography className={classes.question}>
              17* - Qual o grau de competência que você julga ter para encarar o
              desafio da transformação digital a sua vida profissional.
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.answer}>
            <FormControl component="fieldset">
              <RadioGroup
                column
                aria-label="position"
                name="position"
                defaultValue="top"
                value={state.question17}
                onChange={e =>
                  setState({ ...state, question17: e.target.value })
                }
              >
                <FormControlLabel
                  value="Nenhuma competência"
                  control={<Radio color="primary" />}
                  label="Nenhuma competência"
                />
                <FormControlLabel
                  value="Reduzida competência"
                  control={<Radio color="primary" />}
                  label="Reduzida competência"
                />
                <FormControlLabel
                  value="Posso encarar"
                  control={<Radio color="primary" />}
                  label="Posso encarar"
                />
                <FormControlLabel
                  value="Muito competente"
                  control={<Radio color="primary" />}
                  label="Muito competente"
                />
                <FormControlLabel
                  value="Extremamente competente"
                  control={<Radio color="primary" />}
                  label="Extremamente competente"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ textAlign: 'center', borderTop: '1px dashed #bbb' }}
          >
            <Button
              className={classes.btnSalvar}
              onClick={() => setConfirm(true)}
            >
              <Check />
              &nbsp;&nbsp; Salvar
            </Button>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 20, textAlign: 'center' }}>
            <Typography
              style={{ color: '#444', fontSize: '0.7rem', fontWeight: 'bold' }}
            >
              Copyright &copy; DIRIN - PROEAD - AIAMIS - 2020.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={openConfirm}
        onClose={() => setConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirma as respostas?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Antes de confirmar revise as suas respostas!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => salvar()} className={classes.btnSalvar}>
            Sim!
          </Button>
          <Button onClick={() => setConfirm(false)} color="primary">
            Não!
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default Form;
