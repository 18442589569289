import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const NotFoundView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="404">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            Essa página não existe
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            Você provavelmente acessou essa página por engano, clique na imagem
            abaixo para voltar para a tela inicial
          </Typography>
          <Box textAlign="center">
            <a href="/">
              <img
                alt="Under development"
                className={classes.image}
                src="/favicon.png"
              />
            </a>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NotFoundView;
