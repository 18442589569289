import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Page from 'src/components/Page';
import SeletorArquivos from '../../components/SeletorArquivos';
import EnvioFormulario from './components/EnvioFormulario';
import EnvioRegistrosFotograficos from './components/EnvioRegistrosFotograficos';
import EnvioTermoUsoImagem from './components/EnvioTermoUsoImagem';

import AuthContext from 'src/contexts/AuthContext';
import api from '../../services/api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ccc',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  imgLogin: {
    [theme.breakpoints.down('sm')]: {
      height: 100,
      width: 100
    },
    [theme.breakpoints.up('sm')]: {
      height: 150,
      width: 190
    }
  },
  container: {
    background: '#eee',
    padding: 10,
    border: '1px solid #ddd',
    borderRadius: 8,
    boxShadow: '-1px -9px 40px #888',
    marginBottom: 20
  },
  gridCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  gridQuestion: {
    padding: 15
  },
  question: {
    color: '#444',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  answer: {
    paddingLeft: 50
  },
  qtAberta: {
    '&:focus': {
      outlineColor: '#b00f14'
    },
    width: '95%',
    border: '1px solid #ddd',
    borderRadius: 8,
    background: '#ddd',
    padding: 10,
    fontSize: 16,
    resize: 'none',
    fontFamily: 'Roboto'
  },
  btnSalvar: {
    margin: 5,
    backgroundColor: '#b00f14',
    color: '#fff',
    '&:hover': {
      background: '#820d10',
      transition: 'background-color 0.5s'
    }
  },
  btnVoltar: {
    margin: 5,
    backgroundColor: '#e5b31a',
    color: '#444',
    '&:hover': {
      backgroundColor: '#c19716',
      transition: 'background-color 0.2s'
    }
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%',
    background: '#fff'
  },
  formLabel: {
    fontWeight: 'bold',
    color: '#b00f14'
  },
  teste: {
    background: 'red'
  },
  inputFile: {
    display: 'none'
  },
  labelFile: {
    width: '100%',
    padding: '10px 5px',
    backgroundColor: '#e5b31a',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    marginTop: '10px',
    cursor: 'pointer',
    margin: 10,
    borderRadius: 8,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#c19716',
      transition: 'background-color 0.2s'
    }
  },
  labelFileTermos: {
    width: '100%',
    padding: '10px 5px',
    backgroundColor: '#0f57b0',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    marginTop: '10px',
    cursor: 'pointer',
    margin: 10,
    borderRadius: 8,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#0e4b95',
      transition: 'background-color 0.2s'
    }
  },
  labelFileDisabled: {
    width: '100%',
    padding: '10px 5px',
    backgroundColor: '#756a6a',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    marginTop: '10px',
    cursor: 'pointer',
    margin: 10,
    borderRadius: 8,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#544d4d',
      transition: 'background-color 0.2s'
    }
  },
  labelFileSelected: {
    width: '100%',
    padding: '10px 5px',
    backgroundColor: '#078b2f',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    marginTop: '10px',
    cursor: 'pointer',
    margin: 10,
    borderRadius: 8,
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#0d7f25',
      transition: 'background-color 0.2s'
    }
  },
  btnExcluir: {
    background: 'transparent',
    '&:hover': {
      background: 'transparent'
    },
    '&:click': {
      background: 'transparent'
    }
  },
  editorText: {
    background: '#fff',
    borderRadius: 8,
    border: '1px solid #ccc',
    height: '200px'
  },
  areaFieldset: {
    padding: 4,
    border: '1px solid #444',
    width: '100%',
    margin: '10px 0px',
    borderRadius: 4,
    background: '#dedede'
  },
  legendTitle: {
    padding: '0px 8px',
    textTransform: 'uppercase',
    fontFamily: 'roboto',
    fontWeight: 700,
    fontSize: 12,
    color: '#222'
  }
}));

export const SEMINARIOS = [
  'SEMI3',
  'SEMI4',
  'SEMI5',
  'SEMI6',
  'SEMI7',
  'SEMI8'
];
const PortifolioForm = () => {
  const classes = useStyles();
  const { ra } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const idSeminario = useParams().id_seminario;
  const course = sessionStorage.getItem('@c@cous@eea@');
  const [idPortifolio, setIdPortifolio] = useState('');

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    'Formulário',
    'Registros Fotográficos',
    'Termo de Uso de Imagem'
  ];

  const [pageLoading, setPageLoading] = useState(false);
  const [loadingEnvioFormulario, setLoadingEnvioFormulario] = useState(false);

  const [isSeminarThree, setIsSeminarThree] = useState(false);

  const [openConfirm, setConfirm] = useState(false);
  const [openSelecionarImagens, setOpenSelecionarImagens] = useState(false);
  const [openSelecionarTermos, setOpenSelecionarTermos] = useState(false);

  const [imagens, setImagens] = useState([]);
  const [termos, setTermos] = useState([]);

  useEffect(() => {
    async function getSeminarioPortfolio() {
      await api
        .post('seminarios/course-ra', {
          title_curso: course,
          ra
        })
        .then(({ data }) => {
          setPageLoading(false);

          if (SEMINARIOS.includes(data.seminarios[0].cod)) {
            setIsSeminarThree(true);
          } else {
            setIsSeminarThree(false);
          }

          const seminario = data.seminarios.find(
            seminario => seminario.id === parseInt(idSeminario)
          );
          if (seminario.portfolio) {
            navigate('/app/list-portifolio');
          }
        })
        .catch(e => {
          console.log(e);
        });
    }

    if (ra) {
      setPageLoading(true);
      getSeminarioPortfolio();
    }
  }, []);

  // O Loading será mostrado em quanto a página carrega
  if (pageLoading) {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: 20,
            gap: 10
          }}
        >
          <Typography
            style={{ color: '#b00f14', fontWeight: 'bold', fontSize: 18 }}
          >
            Por favor, aguarde...
          </Typography>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Page className={classes.root} title="Formulário de Portifolio">
      <Container>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.gridCenter}>
            <img
              alt="Logo AIAMIS"
              className={classes.imgLogin}
              src="/favicon.png"
            />

            <Typography
              style={{
                color: '#444',
                fontSize: '1.8rem',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              Construção do Portifolio
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ margin: '1rem 0' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>

          {activeStep === 0 && (
            <EnvioFormulario
              ra={ra}
              idSeminario={idSeminario}
              isSeminarThree={isSeminarThree}
              setIdPortifolio={setIdPortifolio}
              setActiveStep={setActiveStep}
            />
          )}

          {activeStep === 1 && (
            <EnvioRegistrosFotograficos
              idPortifolio={idPortifolio}
              setActiveStep={setActiveStep}
            />
          )}

          {activeStep === 2 && (
            <EnvioTermoUsoImagem
              idSeminario={idSeminario}
              idPortifolio={idPortifolio}
              setActiveStep={setActiveStep}
            />
          )}

          <Grid item xs={12} style={{ marginTop: 20, textAlign: 'center' }}>
            <Typography
              style={{ color: '#444', fontSize: '0.7rem', fontWeight: 'bold' }}
            >
              Copyright &copy; DIRIN - PROEAD - AIAMIS - 2020.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* Confirmar Envio */}

      {/* Dialog Selecionar Termos */}
      <Dialog
        open={openSelecionarTermos}
        onClose={() => {
          setOpenSelecionarTermos(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={{ background: '#b00f14' }}>
          <Typography
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: 20,
              color: '#fff'
            }}
          >
            Selecionar Termos de cessão de direito de uso de imagens
          </Typography>
        </DialogTitle>

        <DialogContent>
          <SeletorArquivos
            state={termos}
            setState={setTermos}
            setOpen={setOpenSelecionarTermos}
            type="termo_img"
          />
        </DialogContent>
      </Dialog>

      {/* Dialog Selecionar Registros Fotogŕaficos */}
      <Dialog
        open={openSelecionarImagens}
        onClose={() => {
          setOpenSelecionarImagens(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle style={{ background: '#b00f14' }}>
          <Typography
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: 20,
              color: '#fff'
            }}
          >
            Selecionar Registros Fotográficos
          </Typography>
        </DialogTitle>

        <DialogContent>
          <SeletorArquivos
            state={imagens}
            setState={setImagens}
            setOpen={setOpenSelecionarImagens}
          />
        </DialogContent>
      </Dialog>
    </Page>
  );
};

export default PortifolioForm;
