import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
  PDFDownloadLink
} from '@react-pdf/renderer';
import moment from 'moment';
import latoRegular from '../../../../assets/fonts/Lato-Regular.ttf';
import latoBold from '../../../../assets/fonts/Lato-Bold.ttf';
import latoLight from '../../../../assets/fonts/Lato-Light.ttf';
import { SEMINARIOS } from '../..';

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: latoRegular
    },
    {
      src: latoBold,
      fontWeight: 'bold'
    },
    {
      src: latoLight,
      fontWeight: 'light'
    }
  ]
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontSize: '12pt',
    fontFamily: 'Lato'
  },
  section: {
    margin: 20,
    padding: 20,
    textAlign: 'justify'
  },
  capaLogo: { alignItems: 'center', marginTop: 16 },
  subTitle: {
    fontSize: '18pt',
    color: '#8e0f3a',
    fontWeight: 'bold',
    marginBottom: 8
  },
  key: {
    fontWeight: 'bold'
  },
  viewKeyValue: { flexDirection: 'row', marginBottom: 4 },
  apresentacaoText: {
    textIndent: '15mm',
    marginBottom: 4
  },
  viewImage: {
    width: '50%',
    borderWidth: 1,
    borderColor: '#000',
    borderStyle: 'solid'
  }
});
const MyDocument = ({
  nome_aluno,
  curso,
  ra,
  codSeminario,
  nome_empresa_visitada,
  endereco,
  cidade,
  estado,
  apresentacao,
  data_visita,
  horario_chegada_empresa_visitada,
  setores_visitados,
  atividades_realizadas,
  horario_termino_visita,
  desenvolvimento,
  conclusao,
  registros_fotograficos,
  codePDF,
  titulo_seminario,
  questoes
}) => {
  function geraParagrafos(texto) {
    return texto.split('<p>').map(paragrafo => {
      return (
        <Text style={styles.apresentacaoText}>
          {
            new DOMParser().parseFromString(paragrafo, 'text/html').body
              .textContent
          }
        </Text>
      );
    });
  }
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <Image cache src="/static/images/pdf/cabecalho.jpg" /> */}
        <View style={{ backgroundColor: '#921900', height: '16mm' }} />
        <View style={styles.capaLogo}>
          <Image style={{ width: 150 }} src="/static/images/pdf/uninta.png" />
        </View>
        <View style={[styles.section, { textAlign: 'left', marginTop: 100 }]}>
          <Text style={{ fontSize: '36pt', color: '#921900' }}>Portfólio</Text>
          <Text
            style={{
              fontSize: '47pt',
              fontWeight: 'bold',
              color: '#404040'
            }}
          >
            Imersão em ambiente profissional
          </Text>
          <View
            style={{
              height: '1.5mm',
              width: 35,
              backgroundColor: '#921900',
              marginTop: 16,
              marginLeft: 2
            }}
          />
        </View>
        <View
          style={[
            styles.section,
            { marginBottom: 0, paddingTop: 0, marginTop: 200, fontSize: '16pt' }
          ]}
        >
          <Text>{nome_aluno}</Text>
          <Text style={{ marginTop: 4, color: '#667680' }}>
            {moment().format('LL')}
          </Text>
        </View>
        <View style={[styles.section, { marginTop: 0, paddingTop: 0 }]}>
          <Text>
            Para validar este documento acesse:
            https://ambienteprofissional.uninta.edu.br/validacao
          </Text>
          <Text style={{ fontWeight: 'bold' }}>
            Código de Validação: {codePDF}
          </Text>
        </View>
        <View
          fixed
          style={{
            justifyContent: 'flex-end',
            flexGrow: 1
          }}
        >
          <View style={{ height: '11mm', backgroundColor: '#921900' }} />
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View
          fixed
          style={{ backgroundColor: '#921900', height: '3mm', marginTop: 4 }}
        />
        <View
          fixed
          style={{
            height: '1.5mm',
            width: 35,
            backgroundColor: '#921900',
            margin: 40
          }}
        />
        <View style={[styles.section, { marginTop: 0, paddingTop: 0 }]}>
          <Text style={styles.subTitle}>
            Identificação - {titulo_seminario}
          </Text>
          <View style={styles.viewKeyValue}>
            <Text style={styles.key}>Instituição: </Text>
            <Text>Centro Universitário Inta - UNINTA</Text>
          </View>
          <View style={styles.viewKeyValue}>
            <Text style={styles.key}>Curso: </Text>
            <Text>{curso}</Text>
          </View>
          <View style={styles.viewKeyValue}>
            <Text style={styles.key}>Relator: </Text>
            <Text>{nome_aluno}</Text>
          </View>
          <View style={styles.viewKeyValue}>
            <Text style={styles.key}>Ra: </Text>
            <Text>{ra}</Text>
          </View>
          <View style={styles.viewKeyValue}>
            <Text style={styles.key}>Título: </Text>
            <Text>
              Portfólio de Visita Técnica de imersão em ambiente profissional.
            </Text>
          </View>
          <View style={styles.viewKeyValue}>
            <Text style={styles.key}>Empresa/Instituição visitada: </Text>
            <Text>{nome_empresa_visitada}</Text>
          </View>
          <View style={styles.viewKeyValue}>
            <Text style={styles.key}>Endereço: </Text>
            <Text>{endereco}</Text>
          </View>
          <View style={styles.viewKeyValue}>
            <Text style={styles.key}>Cidade: </Text>
            <Text>{cidade}</Text>
          </View>
          <View style={styles.viewKeyValue}>
            <Text style={styles.key}>Estado: </Text>
            <Text>{estado}</Text>
          </View>
        </View>

        <View
          fixed
          style={{
            justifyContent: 'flex-end',
            flexGrow: 1
          }}
        >
          <View style={{ height: '11mm', backgroundColor: '#921900' }} />
        </View>
      </Page>
      {!SEMINARIOS.includes(codSeminario) && (
        <Page wrap size="A4" style={styles.page}>
          <View
            fixed
            style={{ backgroundColor: '#921900', height: '3mm', marginTop: 4 }}
          />
          <View
            fixed
            style={{
              height: '1.5mm',
              width: 35,
              backgroundColor: '#921900',
              margin: 40
            }}
          />

          <View style={[styles.section, { marginTop: 0, paddingTop: 0 }]}>
            <Text style={styles.subTitle}>Apresentação</Text>
            {geraParagrafos(apresentacao)}
          </View>

          <View
            fixed
            style={{
              justifyContent: 'flex-end',
              flexGrow: 1
            }}
          >
            <View style={{ height: '11mm', backgroundColor: '#921900' }} />
          </View>
        </Page>
      )}
      {!SEMINARIOS.includes(codSeminario) && (
        <Page wrap size="A4" style={styles.page}>
          <View
            fixed
            style={{ backgroundColor: '#921900', height: '3mm', marginTop: 4 }}
          />
          <View
            fixed
            style={{
              height: '1.5mm',
              width: 35,
              backgroundColor: '#921900',
              margin: 40
            }}
          />
          <View style={[styles.section, { marginTop: 0, paddingTop: 0 }]}>
            <Text style={styles.subTitle}>Programação</Text>
            <View style={styles.viewKeyValue}>
              <Text style={styles.key}>Data da Visita: </Text>
              <Text>{moment(data_visita).format('L')}</Text>
            </View>
            <View style={styles.viewKeyValue}>
              <Text style={styles.key}>
                Horário de chegada à empresa/Instituição:{' '}
              </Text>
              <Text>
                {horario_chegada_empresa_visitada.replace(':', 'h')}min
              </Text>
            </View>
            <View style={styles.viewKeyValue}>
              <Text style={styles.key}>Horário de término da visita: </Text>
              <Text>{horario_termino_visita.replace(':', 'h')}min</Text>
            </View>
            <Text style={styles.key}>Setores visitados: </Text>
            <Text style={{ textIndent: '1.5cm' }}>
              {geraParagrafos(setores_visitados)}
            </Text>
            <View style={styles.viewKeyValue} />
            <Text style={styles.key}>Atividades realizadas: </Text>
            <Text style={{ textIndent: '1.5cm' }}>
              {geraParagrafos(atividades_realizadas)}
            </Text>
          </View>
          <View
            fixed
            style={{
              justifyContent: 'flex-end',
              flexGrow: 1
            }}
          >
            <View style={{ height: '11mm', backgroundColor: '#921900' }} />
          </View>
        </Page>
      )}
      {SEMINARIOS.includes(codSeminario) ? (
        <Page wrap size="A4" style={styles.page}>
          <View
            fixed
            style={{
              btitulo_seminarioackgroundColor: '#921900',
              height: '3mm',
              marginTop: 4
            }}
          />
          <View
            fixed
            style={{
              height: '1.5mm',
              width: 35,
              backgroundColor: '#921900',
              margin: 40
            }}
          />
          <View style={[styles.section, { marginTop: 0, paddingTop: 0 }]}>
            <Text style={styles.subTitle}>Desenvolvimento</Text>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              {questoes.map((questao, i) => {
                return (
                  <View style={{ width: '100%', marginBottom: '5em' }}>
                    <Text style={styles.questaoTitle}>
                      {`${i + 1} - ${questao.title_questao}`}
                    </Text>
                    <Text style={styles.key}>Resposta:</Text>
                    {questao.respostas.map(resposta => {
                      return (
                        <Text style={{ textIndent: '1.5cm' }}>
                          {resposta.resposta}
                        </Text>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          </View>

          <View
            fixed
            style={{
              justifyContent: 'flex-end',
              flexGrow: 1
            }}
          >
            <View style={{ height: '11mm', backgroundColor: '#921900' }} />
          </View>
        </Page>
      ) : null}
      {!SEMINARIOS.includes(codSeminario) && (
        <Page wrap size="A4" style={styles.page}>
          <View
            fixed
            style={{ backgroundColor: '#921900', height: '3mm', marginTop: 4 }}
          />
          <View
            fixed
            style={{
              height: '1.5mm',
              width: 35,
              backgroundColor: '#921900',
              margin: 40
            }}
          />
          <View style={[styles.section, { marginTop: 0, paddingTop: 0 }]}>
            <Text style={styles.subTitle}>Desenvolvimento</Text>
            {geraParagrafos(desenvolvimento)}
          </View>
          <View
            fixed
            style={{
              justifyContent: 'flex-end',
              flexGrow: 1
            }}
          >
            <View style={{ height: '11mm', backgroundColor: '#921900' }} />
          </View>
        </Page>
      )}
      <Page wrap size="A4" style={styles.page}>
        <View
          fixed
          style={{ backgroundColor: '#921900', height: '3mm', marginTop: 4 }}
        />
        <View
          fixed
          style={{
            height: '1.5mm',
            width: 35,
            backgroundColor: '#921900',
            margin: 40
          }}
        />
        <View style={[styles.section, { marginTop: 0, paddingTop: 0 }]}>
          <Text style={styles.subTitle}>Conclusão</Text>
          {geraParagrafos(conclusao)}
        </View>
        <View
          fixed
          style={{
            justifyContent: 'flex-end',
            flexGrow: 1
          }}
        >
          <View style={{ height: '11mm', backgroundColor: '#921900' }} />
        </View>
      </Page>
      <Page wrap size="A4" style={styles.page}>
        <View
          fixed
          style={{
            btitulo_seminarioackgroundColor: '#921900',
            height: '3mm',
            marginTop: 4
          }}
        />
        <View
          fixed
          style={{
            height: '1.5mm',
            width: 35,
            backgroundColor: '#921900',
            margin: 40
          }}
        />
        <View style={[styles.section, { marginTop: 0, paddingTop: 0 }]}>
          <Text style={styles.subTitle}>Registro Fotográfico</Text>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {registros_fotograficos.map(foto => {
              return (
                <View style={styles.viewImage}>
                  <Image style={{ objectFit: 'fill' }} src={foto.image} />
                </View>
              );
            })}
          </View>
        </View>

        <View
          fixed
          style={{
            justifyContent: 'flex-end',
            flexGrow: 1
          }}
        >
          <View style={{ height: '11mm', backgroundColor: '#921900' }} />
        </View>
      </Page>
      {!SEMINARIOS.includes(codSeminario) && (
        <Page wrap size="A4" style={styles.page}>
          <View
            fixed
            style={{
              btitulo_seminarioackgroundColor: '#921900',
              height: '3mm',
              marginTop: 4
            }}
          />
          <View
            fixed
            style={{
              height: '1.5mm',
              width: 35,
              backgroundColor: '#921900',
              margin: 40
            }}
          />
          <View style={[styles.section, { marginTop: 0, paddingTop: 0 }]}>
            <Text style={styles.subTitle}>Questões do Roteiro</Text>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
              {questoes.map((questao, i) => {
                return (
                  <View style={{ width: '100%', marginBottom: '5em' }}>
                    <Text style={styles.questaoTitle}>
                      {`${i + 1} - ${questao.title_questao}`}
                    </Text>
                    <Text style={styles.key}>Resposta:</Text>
                    {questao.respostas.map(resposta => {
                      return (
                        <Text style={{ textIndent: '1.5cm' }}>
                          {resposta.resposta}
                        </Text>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          </View>

          <View
            fixed
            style={{
              justifyContent: 'flex-end',
              flexGrow: 1
            }}
          >
            <View style={{ height: '11mm', backgroundColor: '#921900' }} />
          </View>
        </Page>
      )}
    </Document>
  );
};
export default function GeraPDF({
  portfolio,
  codePDF,
  ra,
  cursos,
  nome,
  children
}) {
  return (
    <PDFDownloadLink
      fileName={`portfolio_${ra}.pdf`}
      style={{ marginRight: 8 }}
      document={
        <MyDocument
          nome_aluno={nome}
          curso={cursos[0]?.nome_curso}
          ra={ra}
          codSeminario={portfolio.seminario.cod}
          nome_empresa_visitada={portfolio.nome_empresa_visitada}
          endereco={portfolio.endereco_empresa_visitada}
          estado={portfolio.estado_empresa_visitada}
          cidade={portfolio.cidade_empresa_visitada}
          apresentacao={portfolio.apresentacao}
          data_visita={portfolio.data_visita}
          horario_chegada_empresa_visitada={
            portfolio.horario_chegada_empresa_visitada
          }
          horario_termino_visita={portfolio.horario_termino_visita}
          setores_visitados={portfolio.setores_visitados}
          atividades_realizadas={portfolio.atividades_realizadas}
          desenvolvimento={portfolio.desenvolvimento}
          conclusao={portfolio.conclusao}
          registros_fotograficos={portfolio.imagens}
          titulo_seminario={portfolio.seminario.titulo_seminario}
          codePDF={codePDF}
          questoes={portfolio.seminario.formulario.questoes}
        />
      }
    >
      {children}
    </PDFDownloadLink>
  );
}
