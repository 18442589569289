import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/styles/makeStyles';

import Edit from '@material-ui/icons/Edit';
import Print from '@material-ui/icons/Print';

import GeraPDF from 'src/views/PortifolioForm/components/GeraPDF';

import { useSnackbar } from 'notistack';
import AuthContext from 'src/contexts/AuthContext';
import showSnack from 'src/utils/snacks';
import api from 'src/services/api';

const useStyles = makeStyles(() => ({
    btnPdf: {
      border: '2px solid #e5b31a',
      borderRadius: 8,
      margin: 5,
      padding: 10,
      color: '#fff',
      background: '#b00f14',
      '&:hover': {
        color: '#fff',
        background: '#850a0e',
        transition: 'background-color 0.5s'
      }
    },
    btnEditar: {
      border: '2px solid #b00f14',
      borderRadius: 8,
      padding: 10,
      margin: 5,
      background: '#e5b31a',
      color: '#444',
      '&:hover': {
        background: '#c19716',
        transition: 'background-color 0.2s'
      }
    },
    infoError: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 14,
        color: '#850a0e'
    }
}));

export default function DialogPdfOrEditPortifolio({
    idSeminario,
    title,
    description,
    open,
    onClosed,
    onActionButtonEdit
}) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { ra } = React.useContext(AuthContext);
    const course = sessionStorage.getItem('@c@cous@eea@');

    const [portfolioPdf, setPortfolioPdf] = useState({});

    React.useEffect(() => {
        if (open) {
            if (!!idSeminario && !!ra)
                api
                    .post('portifolio/getPDF', {
                        id_seminario: idSeminario,
                        ra
                    })
                    .then(({ data }) => {
                        setPortfolioPdf({...data, cursos: [{ nome_curso: course }]});
                    })
                    .catch(() => {
                        showSnack('Oops! Ocorreu um erro!', enqueueSnackbar, 'error');
                    });
            }
    }, [open, idSeminario, ra]);

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClosed();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {
                !idSeminario || !ra ? (
                    <>
                        <DialogContent>
                            <Typography className={classes.infoError}>As informações estão incompletas</Typography>
                        </DialogContent>
                    </>
                ) : (
                    <>
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {description}
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {portfolioPdf?.portfolio?.conclusao ? (
                                <GeraPDF {...portfolioPdf}>
                                    <Button color="primary" className={classes.btnPdf} startIcon={<Print />}>
                                        Gerar PDF
                                    </Button>
                                </GeraPDF>
                            ) : (
                                <div className={classes.btnPdf}>
                                    <CircularProgress
                                        style={{ color: 'white', marginRight: 10 }}
                                        size={20}
                                    />
                                    Preparando PDF
                                </div>
                            )}

                            <Button
                                onClick={onActionButtonEdit}
                                color="primary"
                                className={classes.btnEditar}
                                startIcon={<Edit />}
                            >
                                Editar
                            </Button>
                        </DialogActions>
                    </>
                )
            }
        </Dialog>
    );
}
